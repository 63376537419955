/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import home1 from './image/icon/home1.png';
import home2 from './image/icon/home2.png';
import organisation1 from './image/icon/entreprise1.png';
import organisation2 from './image/icon/entreprise2.png';
import opportunite1 from './image/icon/opportunite1.png';
import opportunite2 from './image/icon/opportunite2.png';
import notifications1 from './image/icon/notifications1.png';
import notifications2 from './image/icon/notification2.png';
import portefeuille1 from './image/icon/portefeuille1.png';
import portefeuille2 from './image/icon/portefeuille2.png';
import abonnement from './image/icon/abonnement.png';
import logo_site from './image/logo-site/logo.png';
import moi from './image/personne.png';
import { Button, Modal } from 'react-bootstrap';
import QRCodePage from './Opportunite/qrcode';
import Opportunite from './Opportunite/opportunite';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import App from './App';

function Header() {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState(location.pathname);
    const [profile, setProfile] = useState([]);
    const [organisations, setOrganisations] = useState([]);
    const [selectedItem, setSelectedItem] = useState(localStorage.getItem('selectedItem') || null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pendingAccount, setPendingAccount] = useState(null);
    const account = JSON.parse(localStorage.getItem('account'));
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const streamRef = useRef(null); // Utilisation de useRef pour stocker le flux de la caméra

    const handlePermissionModalClose = () => setShowPermissionModal(false);

    const handlePermissionModalShow = () => setShowPermissionModal(true);

    const handleGrantPermission = () => {
        // Logique pour demander la permission d'accès à la caméra
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                console.log('Permission accordée');
                // Stocke le flux dans la référence
                streamRef.current = stream;

                // Fermer le modal de permission et ouvrir le modal de scan
                setShowPermissionModal(false);

                // Ouvre le modal Bootstrap pur avec jQuery ou JavaScript pur
                const scanModal = document.getElementById('scanModal');
                const bootstrapModal = new window.bootstrap.Modal(scanModal);
                bootstrapModal.show();

                // Gestion de la fermeture du modal pour arrêter la caméra
                scanModal.addEventListener('hidden.bs.modal', () => {
                    console.log('Modal fermé, arrêt de la caméra');
                    if (streamRef.current) {
                        const tracks = streamRef.current.getTracks();
                        tracks.forEach((track) => {
                            console.log('Arrêt de la piste', track);
                            track.stop();
                        });

                        // Essaye aussi de révoquer les objets URL associés au flux (optionnel si tu utilises un <video> tag)
                        const videoElement = document.querySelector('video');
                        if (videoElement && videoElement.srcObject) {
                            videoElement.srcObject = null;
                        }

                        // Réinitialiser le flux
                        streamRef.current = null;
                    }

                });
            })
            .catch((err) => {
                console.error('Erreur d\'accès à la caméra:', err);
                // Gérer le refus ou l'erreur
                setShowPermissionModal(false);
                alert('Permission refusée ou erreur d\'accès à la caméra.');
            });
    };
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/accounts/me`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                setProfile(response.data);
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/organizations/me`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                setOrganisations(response.data);
            } catch (error) {
                console.error('Error fetching organisation:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setActiveItem(location.pathname);

    }, [location.pathname]);

    const handleNavItemClick = (path) => {
        setActiveItem(path);
    };

    const handleAccountChange = (account) => {
        setPendingAccount(account);
        setIsModalOpen(true);
    };
    const confirmChange = () => {
        setSelectedItem(pendingAccount.id);
        if (pendingAccount.id === profile.id) {
            localStorage.removeItem('organisation');
        } else {
            localStorage.setItem('organisation', JSON.stringify(pendingAccount));
        }
        localStorage.setItem('selectedItem', pendingAccount.id);
        setIsModalOpen(false);
    };

    const deconnexion = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('account');
        localStorage.removeItem('userId');
        localStorage.removeItem('organisation');
        localStorage.removeItem('selectedItem');
        window.location.href = '/login';
    };
    useEffect(() => {
        // Ajouter un gestionnaire d'événements pour garantir que la page reste scrollable après la fermeture du modal
        const scanModalElement = document.getElementById('scanModal');

        const handleModalClose = () => {
            document.body.classList.remove('modal-open');
            document.body.style.overflow = 'auto'; // Réactiver le scroll

            // Stop all video tracks when the modal is closed
            const videos = document.querySelectorAll('video');
            videos.forEach(video => {
                if (video.srcObject) {
                    video.srcObject.getTracks().forEach(track => {
                        track.stop();
                        console.log('Stopped track:', track);
                    });
                }
            });
        };

        if (scanModalElement) {
            scanModalElement.addEventListener('hidden.bs.modal', handleModalClose);
        }

        // Cleanup pour éviter les fuites de mémoire
        return () => {
            if (scanModalElement) {
                scanModalElement.removeEventListener('hidden.bs.modal', handleModalClose);
            }
        };
    }, []); // L'effet ne doit être exécuté qu'une seule fois, lors du montage du composant




    return (


        <>
            <nav className="navbar navbar-expand-lg nav-clr sticky-top">
                <div className="container-fluid px-lg-5 ">


                    <div className='d-flex align-items-center'>
                        <Link className="navbar-brand d-flex" to="/">
                            <img width="45" height="45" src={logo_site} alt="Logo" />
                        </Link>
                        <form className="d-flex collapse navbar-collapse d-none d-lg-flex">
                            <div className="search-container">
                                <FontAwesomeIcon icon={faSearch} />
                                <input type="text" placeholder="Recherche" />
                            </div>
                        </form>
                    </div>

                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="p-0 m-0 collapse navbar-collapse d-none d-lg-flex" id="navbarSupportedContent">

                        <ul className="ms-auto navbar-nav  mb-lg-0 d-flex justify-content-center">
                            <li className={`nav-item ${activeItem === '/' ? 'active' : ''}`} onClick={() => handleNavItemClick('/')}>
                                <Link className="nav-link d-flex flex-column align-items-center" to="/">
                                    <img src={activeItem === '/' ? home2 : home1} alt="Accueil" className="nav-icon" />
                                    <span>Accueil</span>
                                </Link>
                            </li>
                            <li className={`nav-item ${activeItem === '/organisation' ? 'active' : ''}`} onClick={() => handleNavItemClick('/organisation')}>
                                <Link className="nav-link d-flex flex-column align-items-center" to="/organisation">
                                    <img src={activeItem === '/organisation' ? organisation2 : organisation1} alt="Organisation" className="nav-icon" />
                                    <span>Organisations</span>
                                </Link>
                            </li>
                            <li data-bs-toggle="modal"
                                data-bs-target="#opportunite" className={`nav-item ${activeItem === '/opportunite' ? 'active' : ''}`} onClick={() => handleNavItemClick('/opportunite')} >
                                <Link className="nav-link d-flex flex-column align-items-center" to="/opportunite">
                                    <img src={activeItem === '/opportunite' ? opportunite2 : opportunite1} alt="Opportunite" className="nav-icon" />
                                    <span>Opportunités</span>
                                </Link>
                            </li>
                            <li className={`nav-item ${activeItem === '/notification' ? 'active' : ''}`} onClick={() => handleNavItemClick('/notification')}>
                                <Link className="nav-link d-flex flex-column align-items-center" to="/notification">
                                    <img src={activeItem === '/notification' ? notifications2 : notifications1} alt="Notification" className="nav-icon" />
                                    <span>Notifications</span>
                                </Link>
                            </li>
                            <li className={`nav-item image-blend ${activeItem === '/mes-cartes' ? 'active' : ''}`} onClick={() => handleNavItemClick('/mes-cartes')}>
                                <Link className="nav-link d-flex flex-column align-items-center" to="/mes-cartes">
                                    <img src={activeItem === '/mes-cartes' ? portefeuille2 : portefeuille1} alt="Mes cartes" className="nav-icon" />
                                    <span>Cartes</span>
                                </Link>
                            </li>
                            <li className={`nav-item ${activeItem === '/abonnement' ? 'active' : ''}`} onClick={() => handleNavItemClick('/abonnement')}>
                                <Link className="nav-link d-flex flex-column align-items-center" to="/abonnement">
                                    <img src={abonnement} alt="Abonnement" className="nav-icon" />
                                    <span>Abonnements</span>
                                </Link>
                            </li>
                        </ul>

                        <div className="dropdown ms-auto p-0">
                            <a className="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {
                                    account?.photo ? <img src={FILE_URL + account?.photo} width="35px" height='35px' alt="Profile" className="rounded-circle" /> : <img src={moi} width="35px" height='35px' alt="Profile" className="rounded-circle" />
                                }

                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li><Link className="dropdown-item" to="/profil">Profil</Link></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li className="fw-bold px-3">Compte actif</li>
                                <li className="dropdown px-3">
                                    <a className="dropdown-toggle d-flex align-items-center nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={(e) => e.stopPropagation()}>

                                        {selectedItem && (
                                            <span className="ms-2">
                                                {selectedItem === profile.id
                                                    ? `${profile.prenom} ${profile.nom}`
                                                    : organisations.find(org => org.id === selectedItem)?.nom}
                                            </span>
                                        )}
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li key={profile.id}>
                                            <button className="dropdown-item" type="button" onClick={() => handleAccountChange(profile)}>
                                                {profile.prenom} {profile.nom}
                                                {selectedItem === profile.id && <span className="blue-circle"></span>}
                                            </button>
                                        </li>
                                        {organisations.map(organisation => (
                                            <li key={organisation.id}>
                                                <button className="dropdown-item" type="button" onClick={() => handleAccountChange(organisation)}>
                                                    {organisation.nom}
                                                    {selectedItem === organisation.id && <span className="blue-circle"></span>}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                                {/* <li><hr className="dropdown-divider" /></li> */}
                                {/* <li className=''>

                                    <button type='button' className='button-qrcode dropdown-item' onClick={handlePermissionModalShow}>
                                        <FontAwesomeIcon icon={faQrcode} /> &nbsp;
                                        Opportunité
                                    </button>
                                </li> */}
                                <li><hr className="dropdown-divider" /></li>
                                <li onClick={() => deconnexion()}><Link className="dropdown-item" to="#">Deconnexion</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </nav>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                        <Link className="navbar-brand d-flex" to="/">
                            <img width="45" height="45" src={logo_site} alt="Logo" />
                        </Link>
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <form className="d-flex mb-3">
                        <div className="search-container">
                            <FontAwesomeIcon icon={faSearch} />
                            <input type="text" placeholder="Recherche" />
                        </div>
                    </form>
                    <ul className="nav mb-2 mb-lg-0 d-flex flex-column">
                        <li data-bs-dismiss="offcanvas" className={`nav-item ${activeItem === '/' ? 'active' : ''}`} onClick={() => handleNavItemClick('/')}>
                            <Link className="nav-link d-flex align-items-center" to="/">
                                <img src={activeItem === '/' ? home2 : home1} alt="Accueil" className="nav-icon" />
                                <span className='px-3'>Accueil</span>
                            </Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" className={`nav-item ${activeItem === '/organisation' ? 'active' : ''}`} onClick={() => handleNavItemClick('/organisation')}>
                            <Link className="nav-link d-flex  align-items-center" to="/organisation">
                                <img src={activeItem === '/organisation' ? organisation2 : organisation1} alt="Organisation" className="nav-icon" />
                                <span className='px-3'>Organisations</span>
                            </Link>
                        </li>
                        <li data-bs-toggle="modal"
                            data-bs-target="#opportunite" data-bs-dismiss="offcanvas" className={`nav-item ${activeItem === '/opportunite' ? 'active' : ''}`} onClick={() => handleNavItemClick('/opportunite')} >
                            <Link className="nav-link d-flex  align-items-center" to="/opportunite">
                                <img src={activeItem === '/opportunite' ? opportunite2 : opportunite1} alt="Opportunite" className="nav-icon" />
                                <span className='px-3'>Opportunités</span>
                            </Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" className={`nav-item ${activeItem === '/notification' ? 'active' : ''}`} onClick={() => handleNavItemClick('/notification')}>
                            <Link className="nav-link d-flex  align-items-center" to="/notification">
                                <img src={activeItem === '/notification' ? notifications2 : notifications1} alt="Notification" className="nav-icon" />
                                <span className='px-3'>Notifications</span>
                            </Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" className={`nav-item image-blend ${activeItem === '/mes-cartes' ? 'active' : ''}`} onClick={() => handleNavItemClick('/mes-cartes')}>
                            <Link className="nav-link d-flex  align-items-center" to="/mes-cartes">
                                <img src={activeItem === '/mes-cartes' ? portefeuille2 : portefeuille1} alt="Mes cartes" className="nav-icon" />
                                <span className='px-3'>Cartes</span>
                            </Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" className={`nav-item ${activeItem === '/abonnement' ? 'active' : ''}`} onClick={() => handleNavItemClick('/abonnement')}>
                            <Link className="nav-link d-flex align-items-center" to="/abonnement">
                                <img src={abonnement} alt="Abonnement" className="nav-icon" />
                                <span className='px-3'>Abonnement</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="offcanvas-footer">
                    <div className="dropdown ms-auto">
                        <div className='d-flex ms-4 mb-4' data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="nav-link" href="#" role="button" >
                                {
                                    account?.photo ? <img src={FILE_URL + account?.photo} width="35px" height='35px' alt="Profile" className="rounded-circle" /> : <img src={moi} width="35px" height='35px' alt="Profile" className="rounded-circle" />
                                }



                            </div>
                            <div className='px-2'>
                                {profile.prenom} {profile.nom}
                            </div>
                        </div>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li data-bs-dismiss="offcanvas"><Link className="dropdown-item" to="/profil">Profil</Link></li>
                            <li data-bs-dismiss="offcanvas"><hr className="dropdown-divider" /></li>
                            <li className="fw-bold px-3">Compte actif</li>
                            <li className="dropdown px-3">
                                <a className="dropdown-toggle d-flex align-items-center nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={(e) => e.stopPropagation()}>

                                    {selectedItem && (
                                        <span className="ms-2">
                                            {selectedItem === profile.id
                                                ? `${profile.prenom} ${profile.nom}`
                                                : organisations.find(org => org.id === selectedItem)?.nom}
                                        </span>
                                    )}
                                </a>
                                <ul className="dropdown-menu">
                                    <li key={profile.id}>
                                        <button className="dropdown-item" type="button" onClick={() => handleAccountChange(profile)}>
                                            {profile.prenom} {profile.nom}
                                            {selectedItem === profile.id && <span className="blue-circle"></span>}
                                        </button>
                                    </li>
                                    {organisations.map(organisation => (
                                        <li key={organisation.id}>
                                            <button className="dropdown-item" type="button" onClick={() => handleAccountChange(organisation)}>
                                                {organisation.nom}
                                                {selectedItem === organisation.id && <span className="blue-circle"></span>}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            {/* <li><hr className="dropdown-divider" /></li> */}
                            {/* <li className=''>

                                <button data-bs-dismiss="offcanvas" type='button' className='button-qrcode dropdown-item' onClick={handlePermissionModalShow}>
                                    <FontAwesomeIcon icon={faQrcode} /> &nbsp;
                                    Opportunité
                                </button>
                            </li> */}
                            <li><hr className="dropdown-divider" /></li>
                            <li onClick={() => deconnexion()}><Link className="dropdown-item" to="#">Deconnexion</Link></li>
                        </ul>
                    </div>
                </div>

            </div>

            <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Confirm Account Change</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>Etes-vous sûr de vouloir changer de compte?</Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <Button variant="secondary" style={{ width: '10vw' }} onClick={() => setIsModalOpen(false)}>
                        Nom
                    </Button>
                    <Button variant="primary" style={{ width: '10vw' }} onClick={confirmChange}>
                        Oui
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal pour demander la permission d'accès à la caméra */}
            <Modal show={showPermissionModal} onHide={handlePermissionModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Demande de permission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Voulez-vous nous autoriser à accéder à votre caméra pour scanner un QR code ?
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="secondary" style={{ width: '10vw' }} onClick={handlePermissionModalClose}>
                        Non
                    </Button>
                    <Button variant="primary" style={{ width: '10vw' }} onClick={handleGrantPermission}>
                        Oui
                    </Button>
                </Modal.Footer>
            </Modal>
            <Opportunite />

            {/* Modal pour scanner le QR Code, en utilisant QRCodePage */}
            <div className="modal fade" id="scanModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-4" id="exampleModalLabel">Scan QR Code</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <QRCodePage />
                        </div>
                    </div>
                </div>
            </div>
            <App />
        </>
    );
}

export default Header;