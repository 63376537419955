/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Bounce, Slide, Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import date_image from '../image/date-limite.png'

function EvenementForm({ onEventAdded, onEventUpdated, eventToEdit, isEditMode }) {
    const apiUrl = 'https://api.holicorp.pro';
    const idOrganisation = JSON.parse(localStorage.getItem("organisation"))?.id;

    const [formData, setFormData] = useState({
        couverture: null,
        titre: '',
        lieu: '',
        date: '',
        description: '',
        ...(idOrganisation && { organisationId: idOrganisation })
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    // const [showDateModal, setShowDateModal] = useState(false);

    useEffect(() => {
        if (eventToEdit) {
            setFormData({
                couverture: null,
                titre: eventToEdit.titre,
                date: eventToEdit.date,
                lieu: eventToEdit.lieu,
                description: eventToEdit.description,
                ...(idOrganisation && { organisationId: idOrganisation })
            });

        }
    }, [idOrganisation, eventToEdit]);
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            date: date.toISOString().split('T')[0]
        });
        // setShowDateModal(false);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.titre) newErrors.titre = 'Le titre est requis';
        if (!formData.description) newErrors.description = 'La description est requise';
        if (!formData.date) newErrors.description = 'La date est requise';
        if (!formData.lieu) newErrors.lieu = 'Le lieu est requis';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!idOrganisation) {
            notify("Ce compte n'est pas autorisé à créer un evenement", 'error');
            return;
        }
        if (!validateForm()) return;

        setLoading(true);
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
        const accessToken = localStorage.getItem('accessToken');
        const url = isEditMode ? `${process.env.REACT_APP_API_URL}/api/v1/events/${eventToEdit.id}` : `${process.env.REACT_APP_API_URL}/api/v1/events`;
        const method = isEditMode ? 'PUT' : 'POST';
        fetch(url, {
            method: method,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: data
        }).then(response => response.json())
            .then(data => {
                console.log('SuccessEvenement:', data);
                notify(isEditMode ? "Evenement modifié avec succès !" : "Evenement ajouté avec succès !");
                setLoading(false);
                if (isEditMode) {
                    onEventUpdated(data);
                } else {
                    onEventAdded(data);
                }
                onEventAdded(data);
            })
            .catch((error) => {
                console.error('ErrorPost:', error);
                notify("Erreur lors de l'ajout d'evenement", 'error');
                setLoading(false);
            });
    };
    return (
        <>

            <div class="modal fade" id="addEvenModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-lg modal-dialog modal-dialog-centered  modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Créer Evénement</h1>

                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <form onSubmit={handleSubmit} encType="multipart/form-data">

                                <div className='mb-3'>

                                    <label htmlFor="titre" class="form-label">Titre</label>
                                    <input type="text" className={`form-control ${errors.titre ? 'is-invalid' : ''}`} id="titre" name="titre" value={formData.titre} onChange={handleChange} placeholder="Veuillez saisir le titre" />
                                    {errors.titre && <div className="invalid-feedback">{errors.titre}</div>}

                                </div>
                                <div className='mb-3'>

                                    <label htmlFor="lieu" class="form-label">Adresse</label>
                                    <input type="text" className={`form-control ${errors.lieu ? 'is-invalid' : ''}`} id="lieu" name="lieu" value={formData.lieu} onChange={handleChange} placeholder="Veuillez saisir le lieu" />
                                    {errors.lieu && <div className="invalid-feedback">{errors.lieu}</div>}

                                </div>


                                <div className='mb-3'>

                                    <label htmlFor="date" class="form-label">Date</label>
                                    <div className="input-group">
                                        <input type="text" className={`form-control ${errors.date ? 'is-invalid' : ''}`} id="date" name="date" value={formData.date} onChange={handleChange} placeholder="Veuillez saisir la date" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" />
                                        <span className="input-group-text" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">
                                            <img src={date_image} width="30" alt='..' />
                                        </span>
                                        {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                                    </div>

                                </div>

                                <div className='mb-3'>

                                    <label htmlFor="couverture" className="form-label">Image</label>
                                    <input type="file" className="form-control" id="couverture" name="couverture" onChange={handleChange} />

                                </div>

                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <textarea id="description" className={`form-control ${errors.description ? 'is-invalid' : ''}`} name="description" value={formData.description} onChange={handleChange} required placeholder="Veuillez saisir la description de votre evenement"></textarea>
                                    {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                                </div>

                                <div className="w-100 d-flex justify-content-end">
                                    <button className='btn-ad' type="submit" disabled={loading}>
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Ajouter'}
                                    </button>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal fade" id="exampleModalToggle2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalToggle2" aria-hidden="true">
                <div class="modal-lg modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalToggle2">Date de l'evenement</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" >
                            <div className='date-piker-style' data-bs-target="#addEvenModal" data-bs-toggle="modal">
                                <DatePicker

                                    selected={formData.date ? new Date(formData.date) : null}
                                    onChange={handleDateChange}
                                    inline
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <div class="modal fade" id="addateModal" tabindex="-1"
                aria-labelledby="addateModal" aria-hidden="true">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="addateModal">Créer Evénement</h1>

                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className=''>
                            <DatePicker
                                data-bs-dismiss="modal" data-bs-target="#addateModal"
                                selected={formData.date ? new Date(formData.date) : null}
                                onChange={handleDateChange}
                                inline
                            />
                        </div>

                    </div>

                </div>
            </div> */}
            {/* <Modal show={showDateModal} onHide={() => setShowDateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Sélectionner une date</Modal.Title>
                </Modal.Header>
                <Modal.Body className="position-relative">
                    <div className=''>
                        <DatePicker
                            selected={formData.date ? new Date(formData.date) : null}
                            onChange={handleDateChange}
                            inline
                        />
                    </div>

                </Modal.Body>
            </Modal> */}
            <div>
                <ToastContainer transition={Zoom} />
            </div>

        </>

    )
}

export default EvenementForm;