/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line
import moi from '../image/personne.png';
import cover from '../image/holileran_cover.jpeg';
import logo from '../image/logo-site/logo.png';
import email from '../image/Organisation-logo/email.png'
import phone from '../image/Organisation-logo/phone.png'
import adresse from '../image/Organisation-logo/localisation.png'
// eslint-disable-next-line
import linkeldin from '../image/linkedin.png'
import web from '../image/Organisation-logo/web.png'
// import Left from '../Accueil/Left'
import '../Profil/Profil.css'
import modifier from '../image/modifier.png'
// import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react'; // Use QRCodeCanvas or QRCodeSVG

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useParams } from 'react-router-dom';
import CarteForm from './AddCarte';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faSquareInstagram, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
function CartesDetail() {
    const [cartes, setCartes] = useState([]);
    const { id } = useParams();
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const currentUser = JSON.parse(localStorage.getItem('account'))
    // Créer la chaîne à partir des données JSON
    const qrData = JSON.stringify(
        currentUser?.id + "/" +
        currentUser?.nom + "/" +
        currentUser?.prenom
    );


    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const [comptesCarteResponse, organisationsCarteResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/api/v1/cards/compte`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}/api/v1/cards/organisation`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    })
                ]);

                const combinedCarte = [...comptesCarteResponse.data, ...organisationsCarteResponse.data.membres, ...organisationsCarteResponse.data.clients];
                console.log(combinedCarte);
                combinedCarte.map((carte, index) => {
                    if (carte?.id === id) {
                        setCartes(carte)
                    }
                });
                setLoading(false);
            } catch (error) {
                console.error('Error fetching posts:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);


    // const handleProfilUpdated = (updatedProjet) => {
    //     setCartes(updatedProjet);
    // };
    if (loading) {
        return (
            <div className="row m-1">
                <div className="col-1"></div>
                <div className="col-12 col-md-12 col-sm-12 col-lg-8">
                    <div className="right my-2">
                        <Skeleton height={200} className="cover-picture-style" />

                        <div className='d-flex justify-content-around align-items-center pb-0'>
                            <Skeleton circle={true} height={100} width={100} className="profil-picture-style" />
                            <div className="container-name-presentation">
                                <Skeleton width={150} height={20} />
                                <Skeleton width={100} height={20} />
                            </div>
                            <Skeleton circle={true} height={30} width={30} />
                        </div>
                    </div>

                    <div className="right my-2 px-5 py-2">
                        <Skeleton width={100} height={20} />
                        <Skeleton count={3} />
                    </div>
                </div>

                <div className="col-12 col-md-12 col-sm-12 col-lg-3">
                    <div className="right my-2 px-2 py-2">
                        <Skeleton height={300} className='w-100' />
                    </div>

                    <div className='right my-2 py-2'>
                        <Skeleton width={100} height={20} />
                        <div className="d-flex justify-content-center">
                            <Skeleton height={256} width={256} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const { facebook, twitter, linkedin, instagram } = cartes?.social || {};
    const hasSocial = facebook || twitter || linkedin || instagram;

    return (
        <div className="row m-1">
            <div className="col-1"></div>
            <div className="col-12 col-md-12 col-sm-12 col-lg-8">
                <div className="right my-2">
                    {
                        cartes?.couverture ? <img src={FILE_URL + cartes?.couverture} alt="Profile" className="cover-picture-style" /> : <img src={cover} alt="Profile" className="cover-picture-style" />
                    }


                    <div className='d-flex justify-content-around align-items-center pb-0'>
                        {
                            cartes?.photo ? <img src={FILE_URL + cartes?.photo} width="35px" height='35px' alt="Profile" className="profil-picture-style" /> : <img src={moi} width="35px" height='35px' alt="Profile" className="profil-picture-style" />
                        }
                        {/* <img src={FILE_URL + cartes.photo} alt="cover" className="profil-picture-style" /> */}
                        <div className="container-name-presentation">
                            <h4 className="text-black">{cartes?.prenom} {cartes?.nom}</h4>
                            <span>
                                {
                                    cartes?.profession
                                }
                            </span>
                        </div>
                        {/* <button type='button' data-bs-toggle="modal" data-bs-target="#addCarteModal" onClick={() => setIsEditMode(true)} className="btn btn-sm ms-2">

                            <img src={modifier} width="30px" alt='modifier' />
                        </button> */}
                    </div>
                </div>

                <div className="right my-2 px-2  py-2">
                    <h5 >
                        Apropos
                    </h5>
                    <span>
                        {
                            cartes?.presentation
                        }
                    </span>

                    <div className='identifiant mt-2'>
                        <div className='d-flex mb-2'>
                            <img src={email} className='image-icon' alt='...' />
                            <div>{cartes?.emails && cartes?.emails.length > 0 ? cartes.emails[0]?.email : "Pas d'email disponible"}</div>
                        </div>

                        <div className='d-flex mb-2'>
                            <img src={phone} className='image-icon' alt='...' />
                            <div>{cartes?.phones && cartes?.phones.length > 0 ? cartes.phones[0]?.phone : "Pas de numéro de téléphone"}</div>
                        </div>
                        <div className='d-flex mb-2'>
                            <img src={adresse} className='image-icon' alt='...' />
                            {
                                cartes?.adresse ? <div>{cartes?.adresse}</div> : <div>Pas d'adresse</div>
                            }
                        </div>

                        <div className='d-flex mb-2'>
                            <img src={web} className='image-icon' alt='...' />
                            {
                                cartes?.site ? <div>{cartes.site}</div> : <div>holilink.ml</div>
                            }
                        </div>

                    </div>
                </div>

            </div>


            <div className="col-12 col-md-12 col-sm-12 col-lg-3">
                <div className="right my-2 px-2 py-2">
                    <div className="video-container" key={cartes?.id}>


                        <video controls className='w-100'
                            height="300">
                            <source src={FILE_URL + cartes?.video} type="video/webm" />

                            <source src={FILE_URL + cartes?.video} type="video/mp4" />

                            Download the
                            <a href={FILE_URL + cartes?.video}>WEBM</a>
                            or
                            <a href={FILE_URL + cartes?.video}>MP4</a>
                            video.
                        </video>
                    </div>
                </div>

                <div className='right my-2 py-2'>

                    <div className=" d-flex justify-content-center ">

                        <QRCodeCanvas value={qrData} size={256} level={"H"} />
                    </div>
                </div>

                {hasSocial && (
                    <div className='right my-2 py-2'>
                        {facebook && (
                            <div className='d-flex align-items-center mb-2'>
                                <FontAwesomeIcon className='image-icon' icon={faFacebook} />
                                <div>{facebook}</div>
                            </div>
                        )}
                        {twitter && (
                            <div className='d-flex align-items-center mb-2'>
                                <FontAwesomeIcon className='image-icon' icon={faSquareXTwitter} />
                                <div>{twitter}</div>
                            </div>
                        )}
                        {linkedin && (
                            <div className='d-flex align-items-center mb-2'>
                                <FontAwesomeIcon className='image-icon' icon={faLinkedin} />
                                <div>{linkedin}</div>
                            </div>
                        )}
                        {instagram && (
                            <div className='d-flex align-items-center mb-2'>
                                <FontAwesomeIcon className='image-icon' icon={faSquareInstagram} />
                                <div>{instagram}</div>
                            </div>
                        )}
                    </div>
                )}

            </div>

        </div>
    )

}

export default CartesDetail;