import React, { useState } from 'react';
import { useEffect } from 'react';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const OrganizationForm = ({ onOrganisationAdded, onOrganisationUpdated, OrganisationToEdit, isEditMode }) => {
    
    const [loading, setLoading] = useState(false);
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const [formData, setFormData] = useState({
        logo: null,
        couverture: null,
        nom: '',
        email: '',
        phone: '',
        site: '',
        geolocalisation: '',
        adresse: '',
        domaine: '',
        type: '',
        employes: '',
        apropos: ''
    });


    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    useEffect(() => {
        if (OrganisationToEdit) {
            setFormData({
                logo: null,
                couverture: null,
                nom: OrganisationToEdit.nom,
                email: OrganisationToEdit.email,
                phone: OrganisationToEdit.phone,
                site: OrganisationToEdit.site,
                geolocalisation: OrganisationToEdit.geolocalisation,
                adresse: OrganisationToEdit.adresse,
                domaine: OrganisationToEdit.domaine,
                type: OrganisationToEdit.type,
                employes: OrganisationToEdit.employes,
                apropos: OrganisationToEdit.apropos

            });
            // setCurrentImage(OrganisationToEdit.couverture); // Mettez à jour l'image actuelle
        }
    }, [OrganisationToEdit]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData();

        setLoading(true);
        for (const key in formData) {
            data.append(key, formData[key]);
        }

        const accessToken = localStorage.getItem('accessToken');
        const url = isEditMode ? `${process.env.REACT_APP_API_URL}/api/v1/organizations/${OrganisationToEdit.id}` : `${process.env.REACT_APP_API_URL}/api/v1/organizations`;
        const method = isEditMode ? 'PUT' : 'POST';
        fetch(url, {
            method: method,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: data
        }).then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                notify(isEditMode ? "Organisation modifié avec succès !" : "Organisation ajouté avec succès !");
                setLoading(false);
                if (isEditMode) {
                    onOrganisationUpdated(data);
                } else {
                    onOrganisationAdded(data);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    };

    return (
        <div class="modal fade" id="addOrganisation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-lg modal-dialog modal-dialog-centered  modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        {
                            isEditMode ? <h1 className="modal-title fs-4" id="exampleModalLabel">Modifier Organisation</h1> : <h1 className="modal-title fs-4" id="exampleModalLabel">Créer Organisation</h1>
                        }
                        
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className='mb-3'>
                                <label htmlFor="logo" class="form-label">Logo *</label>
                                <input type="file" class="form-control" id="logo" name="logo" onChange={handleChange}  />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor="couverture" class="form-label">Couverture</label>
                                <input type="file" class="form-control" id="couverture" name="couverture" onChange={handleChange} />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor="nom" class="form-label">Nom *</label>
                                <input type="text" class="form-control" id="nom" name="nom" value={formData.nom} onChange={handleChange}  />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor="email" class="form-label">Email *</label>
                                <input type="email" class="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor="phone" class="form-label">Phone *</label>
                                <input type="tel" class="form-control" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor="site" class="form-label">Site *</label>
                                <input type="text" class="form-control" id="site" name="site" value={formData.site} onChange={handleChange} required />
                            </div>

                            {/* <div className='mb-3'>
                                <label htmlFor="geolocalisation" class="form-label">Geolocalisation</label>
                                <input type="text" class="form-control" id="geolocalisation" name="geolocalisation" value={formData.geolocalisation} onChange={handleChange} />
                            </div> */}

                            <div className='mb-3'>
                                <label htmlFor="adresse">Adresse *</label>
                                <input type="text" class="form-control" id="adresse" name="adresse" value={formData.adresse} onChange={handleChange} required />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor="domaine" class="form-label">Domaine *</label>
                                <input type="text" class="form-control" id="domaine" name="domaine" value={formData.domaine} onChange={handleChange} required />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor="type" class="form-label">Type *</label>
                                <input type="text" class="form-control" id="type" name="type" value={formData.type} onChange={handleChange} required />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor="employes" class="form-label">Employes *</label>
                                <input type="text" class="form-control" id="employes" name="employes" value={formData.employes} onChange={handleChange} required />
                            </div>

                            <div className='mb-3'>
                                <label htmlFor="apropos" class="form-label">Apropos *</label>
                                <textarea id="apropos" class="form-control" name="apropos" value={formData.apropos} onChange={handleChange} required></textarea>
                            </div>
                            <div class="d-grid gap-2 col-6 mx-auto">

                                <button class="btn btn-primary" type="submit" disabled={loading}>
                                    {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : isEditMode ? 'Modifier' : 'Ajouter'}
                                </button>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
            <ToastContainer transition={Bounce} />
        </div>

    );
};

export default OrganizationForm;