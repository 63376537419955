import React, { useState } from 'react';
import add from '../image/icon-card/add.png';
import { Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QuestionnaireForm = ({ onSondageAdded, onSondageUpdated, sondageToEdit, isEditMode }) => {
    
    const idOrganisation = JSON.parse(localStorage.getItem("organisation"))?.id;
    const [couverture, setCouverture] = useState(null);
    const [question, setQuestion] = useState('');
    const [multiple, setMultiple] = useState(false);
    const [options, setOptions] = useState([{ value: '', isEditable: true }]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!question) newErrors.titre = 'La question est requise';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index].value = value;
        setOptions(newOptions);
    };



    const addOptionField = () => {
        if (options[options.length - 1].value === '') {
            notify("Veuillez remplir l'option avant d'ajouter une autre.", 'error');
            return;
        }

        const newOptions = options.map((option) => ({
            ...option,
            isEditable: false,
        }));
        setOptions([...newOptions, { value: '', isEditable: true }]);
    };

    const removeOptionField = (index) => {
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!idOrganisation) {
            notify("Ce compte n'est pas autorisé à créer un sondage", 'error');
            return;
        }
        if (!validateForm()) return;
        setLoading(true);

        const data = new FormData();
        data.append('couverture', couverture);
        data.append('question', question);
        data.append('multiple', multiple);

        data.append('options', JSON.stringify(options.map(option => ({ option: option.value }))));
        if (idOrganisation) {
            data.append('organisationId', idOrganisation);
        }

        const accessToken = localStorage.getItem('accessToken');
        const url = isEditMode ? `${process.env.REACT_APP_API_URL}/api/v1/surveys/${sondageToEdit.id}` : `${process.env.REACT_APP_API_URL}/api/v1/surveys`;
        const method = isEditMode ? 'PUT' : 'POST';
        fetch(url, {
            method: method,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: data
        }).then(response => response.json())
            .then(data => {
                console.log('Success Sondage:', data);

                notify(isEditMode ? "Sondage modifié avec succès !" : "Sondage ajouté avec succès !");
                setLoading(false);
                if (isEditMode) {
                    onSondageUpdated(data);
                } else {
                    onSondageAdded(data);
                }
                // onSondageAdded(data)
            })
            .catch((error) => {
                console.error('ErrorPost:', error);
                notify("Erreur lors de l'ajout du sondage", 'error');
                setLoading(false);
            });
    };

    return (
        <>
            <div className="modal fade" id="addSondageModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            {
                                isEditMode ? <h1 className="modal-title fs-4" id="exampleModalLabel">Modifier Evénement</h1> : <h1 className="modal-title fs-4" id="exampleModalLabel">Créer Evénement</h1>
                            }
                            
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                <div className='mb-3'>
                                    <label htmlFor="question" className="form-label">Question</label>
                                    <input
                                        type="text"
                                        name="question"
                                        id="question"
                                        className={`form-control`}
                                        value={question}
                                        onChange={(e) => setQuestion(e.target.value)}
                                        placeholder="Saisir une question du sondage"
                                        required
                                    />
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor="couverture" className="form-label">Couverture</label>
                                    <input
                                        type="file"
                                        id='couverture'
                                        name='couverture'
                                        onChange={(e) => setCouverture(e.target.files[0])}
                                        className={`form-control`}

                                    />
                                </div>
                                <div className='mb-3 form-control'>
                                    <label htmlFor="option" className="form-label">Options</label>
                                    {options.map((option, index) => (
                                        <div key={index}>
                                            {option.isEditable && (
                                                <input
                                                    type="text"
                                                    className={`form-control`}
                                                    value={option.value}
                                                    onChange={(e) => handleOptionChange(index, e.target.value)}
                                                    placeholder={`Le titre de l'option`}
                                                />
                                            )}
                                        </div>
                                    ))}
                                    <div
                                        type="button"
                                        onClick={addOptionField}
                                        className='form-control d-flex justify-content-start my-2'
                                    >
                                        <div className='mx-2'>
                                            <img className='icon-add-circle' src={add} alt='ajouter icon' />
                                        </div>
                                        <div>
                                            Ajouter une option
                                        </div>
                                    </div>
                                    {options.map((option, index) => (
                                        <div key={index}>
                                            {!option.isEditable && (
                                                <div type="button" className='form-control my-2' onClick={() => removeOptionField(index)}>
                                                    {option.value}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className='mb-3 d-flex justify-content-between'>
                                    <div>
                                        <input
                                            type="checkbox"
                                            name="multiple"
                                            id="multiple"
                                            checked={multiple}
                                            onChange={(e) => setMultiple(e.target.checked)}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="multiple" className="form-label">Multiple</label>
                                    </div>
                                </div>
                                <div className="w-100 d-flex justify-content-end">
                                    <button className='btn-ad' type="submit" disabled={loading}>
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Ajouter'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer transition={Zoom} />
        </>
    );
};

export default QuestionnaireForm;
