/* eslint-disable jsx-a11y/anchor-is-valid */
import seting from '../image/seting.png'
import './Notification.css'
import '../Accueil/Left.css'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { formatDistanceToNow, parseISO, differenceInDays, differenceInWeeks } from 'date-fns';
import { fr } from 'date-fns/locale';
import Left from '../Accueil/Left';
import moi from '../image/personne.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Assure d'importer le CSS pour les styles par défaut
import { toast, ToastContainer, Zoom } from 'react-toastify';

function Notification() {
    const formatCustomDistance = (date) => {
        const now = new Date();
        const parsedDate = parseISO(date);
        const daysDifference = differenceInDays(now, parsedDate);
        const weeksDifference = differenceInWeeks(now, parsedDate);

        if (daysDifference < 7) {
            return formatDistanceToNow(parsedDate, { addSuffix: true, locale: fr });
        } else {
            return `il y a ${weeksDifference} ${weeksDifference > 1 ? 'semaines' : 'semaine'}`;
        }
    };
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/";
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/notifications`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                // Trier les posts par date de création (du plus récent au plus ancien)
                // const sortedEvent = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                // Sélectionner les six derniers posts
                // const latestEvent = sortedEvent.slice(0, 6);
                setNotifications(response.data);
                console.log(response.data)
                setLoading(false);
            } catch (error) {
                console.error('Error fetching posts:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const AccepterNotification = (id, emetteurId, typeId, type) => {
        const accessToken = localStorage.getItem('accessToken');
        const url = `${process.env.REACT_APP_API_URL}/api/v1/notifications/${id}`;
        const method = 'PUT';
        console.log(JSON.stringify({
            type: type,
            typeId: typeId,
            emetteurId: emetteurId,
            recepteurId: localStorage.getItem("account")?.id,
            status: true
        }))
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                'type': type,
                'typeId': typeId,
                'emetteurId': emetteurId,
                'recepteurId': JSON.parse(localStorage.getItem("account"))?.id,
                'status': true
            })
        }).then(response => response.json())
            .then(data => {
                console.log('SuccessProjet:', data);

                notify(data.status === true ? "Operation réussite" : data.message);
                /*setLoading(false);
                if (data.status === true) {
                    onProjetUpdated(data);
                } else {
                    onProjetAdded(data);
                } */
                // Actualiser la page
                // window.location.reload();
            })
            .catch((error) => {
                console.error('ErrorProjet:', error);
                notify("Erreur");
                setLoading(false);
            });

    }
    if (loading) {
        return (

            <div className="row m-2">
                <div className="col-12 col-md-12 col-sm-12 col-lg-8">
                    <div className="right p-2 mx-2 my-2">
                        <Skeleton count={5} height={100} />
                        <Skeleton count={5} height={100} />

                    </div>
                </div>
                <div className="col-4">
                    <Skeleton count={1} height={200} />
                </div>
            </div>
        );
    } else if (notifications.length === 0) {
        return (

            <div className="row m-2">
                <div className="col-12 col-md-12 col-sm-12 col-lg-8">
                    <div className="right p-2 mx-2 my-2">
                        <h6 className='position-absolute top-50 start-50 translate-middle text-center'>Aucune Notification disponible</h6>

                    </div>
                </div>
                <div className="col-4">
                    <Skeleton count={1} height={200} />
                </div>
            </div>

        )
    }

    return (
        <div className="row m-2">
            <div className="col-12 col-md-12 col-sm-12 col-lg-8">
                <div className="right p-2 mx-2 my-2">
                    {
                        notifications.map((notification, index) => (
                            <div className='d-flex border rounded p-1 mx-4 m-2' key={index}>
                                {
                                    notification?.emetteur?.photo ? <img src={FILE_URL + notification?.emetteur?.photo} width="35px" height='35px' alt="Profile" className="rounded-circle" /> : <img src={moi} width="35px" height='35px' alt="Profile" className="rounded-circle" />
                                }
                                {/* <img src={FILE_URL + notification.emetteur.photo} width="45px" height="45px" className='rounded-circle' alt='...' /> */}
                                <div className='px-2 w-100'>
                                    <div className='w-100 d-flex justify-content-between pt-1'>
                                        <div className='notif-title'>
                                            {notification.emetteur.prenom} {notification.emetteur.nom}

                                        </div>
                                        <div class="dropdown mb-2">
                                            <button class="btn  p-0 px-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={seting} width="25px" alt='..' />
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-white">

                                                {/* <li className='d-flex justify-content-start dropdown-item' >
                                                    <img src={detail} className='icon-seting' alt='...' />
                                                    <Link class="nav-link" to="#">Detail</Link>
                                                </li> */}

                                                <li className='d-flex justify-content-start dropdown-item' >
                                                    {/* <img src={supprimer} className='icon-seting-sup' alt='...' /> */}
                                                    <Link class="nav-link" to="#">Supprimer</Link>
                                                </li>
                                                {
                                                    (notification.type === "ajout_carte_par_compte") && (
                                                        <>
                                                            <li className='d-flex justify-content-start dropdown-item' onClick={() => AccepterNotification(notification.id, notification.emetteur.id, notification.typeId, notification.type)}>
                                                                <Link className='nav-link'>Accepter</Link>
                                                            </li>

                                                            <li className='d-flex justify-content-start dropdown-item'>

                                                                <Link className='nav-link'>Refuser</Link>
                                                            </li>

                                                        </>
                                                    )
                                                }




                                            </ul>
                                        </div>

                                    </div>

                                    <div className='notif-content'>
                                        {notification.message}

                                    </div>
                                    <div className='notif-horaire'>
                                        {/* il y a 2 Heures */}
                                        {
                                            formatCustomDistance(notification.createdAt)
                                        }
                                        {/* {notification.createdAt} */}

                                    </div>

                                </div>

                            </div>
                        ))
                    }

                    <ToastContainer transition={Zoom} />

                </div>
            </div>
            <div className="col-4">
                <Left />
            </div>
        </div>
    )

}

export default Notification;