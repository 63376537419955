/* eslint-disable jsx-a11y/anchor-is-valid */
import add from '../image/icon-card/add.png'
import date from '../image/icon-card/date.png'
import location from '../image/icon-card/location.png'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EvenementForm from '../Evenement/AddEvenement';
import Left from '../Accueil/Left';
import SkeletonLoaderPost from '../SkeletonLoad/skeletonloaderPost';
import { Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ListEvenement() {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const [organisation, setOrganisation] = useState(JSON.parse(localStorage.getItem('organisation')) || '');
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    useEffect(() => {
        // Définir un intervalle qui vérifie la valeur dans le localStorage toutes les secondes
        const intervalId = setInterval(() => {
            const currentOrganisation = localStorage.getItem('organisation') || '';
            if (currentOrganisation !== organisation) {
                setOrganisation(currentOrganisation);
            }
        }, 1000); // Intervalle de 1 seconde

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, [organisation]); // Dépendance à l'état "organisation"


    const VerificatioCompte = (e) => {

        if (!organisation) {
            notify('Veillez changer de compte pour pouvoir créer un évenement', 'info')
        } else {
            // Ouvre le modal Bootstrap pur avec jQuery ou JavaScript pur
            const scanModal = document.getElementById('addEvenModal');
            const bootstrapModal = new window.bootstrap.Modal(scanModal);
            bootstrapModal.show();
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/events`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                setEvents(response.data);
                console.log(response.data.length)
                setLoading(false);
            } catch (error) {
                console.error('Error fetching posts:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    const handleEventAdded = (newData) => {
        setEvents([newData, ...events]);
    };
    // const chunkedPosts = chunkArray(events, 2);

    if (loading) {
        return (
            <SkeletonLoaderPost />
        );
    } else if (events.length === 0) {
        return (

            <div className="right p-2 mx-2 my-2 h-25 position-relative">
                <div className='d-flex my-2'>
                    <div className='px-2 txt-head'>
                        Evénements
                    </div>

                    <div className='px-2 btn-add' data-bs-toggle="modal"
                        data-bs-target="#addEvenModal">
                        <img src={add} class="rounded-start flex-shrink-1" alt="..." />
                    </div>

                </div>
                <div className=' w-100 h-75 bg-body-secondary'>

                    <h6 className='position-absolute top-50 start-50 translate-middle text-center'>Aucun Evenement disponible</h6>

                </div>
                <EvenementForm onEventAdded={handleEventAdded} />

            </div>

        )
    }
    return (

        <div className='row m-md-1'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                <div className="right p-2 mx-md-2 my-2">
                    <div className='d-flex my-2'>
                        <div className='px-2 txt-head'>
                            Evénements
                        </div>

                        <div className='px-2 btn-add' onClick={VerificatioCompte}>
                            <img src={add} class="rounded-start flex-shrink-1" alt="..." />
                        </div>

                    </div>
                    <div className='container'>
                        <div className='row'>
                            {events.map(event => (


                                <div key={event.id} className="g-0 my-2 mx-2 card border1 rounded flex-fill col-12 col-sm-12 col-md-12 col-lg-5" style={{ maxWidth: '30vw' }}>
                                    <Link to={`/event-detail/${event.id}`} className='nav-link'>

                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex align-items-center px-3'>
                                                <img src={FILE_URL + event.couverture} className="flex-shrink-0 imageEvent" alt={event.titre} />
                                            </div>


                                            <div className='d-flex align-content-between flex-wrap'>
                                                <div className='px-2 d-flex justify-content-end w-100'>
                                                    <img src={date} alt='...' />
                                                    <div className='px-2'>
                                                        {new Date(event.date).toLocaleDateString()}

                                                    </div>
                                                </div>
                                                <div className='content px-2'>
                                                    <h5 className='prj-title text-truncate' style={{ maxWidth: '20vw' }}>{event.titre}</h5>
                                                    <div className='px-2 description'>
                                                        {event.description}

                                                    </div>
                                                    <div className='px-2 d-flex justify-content-start w-100'>
                                                        <img src={location} alt='...' />
                                                        <h6 className='px-2'>
                                                            {event.lieu}
                                                        </h6>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Link>

                                </div>


                            ))}

                        </div>
                    </div>







                </div>
                <ToastContainer transition={Zoom} />
                <EvenementForm onEventAdded={handleEventAdded} />

            </div>

            <div className='col-4'>
                <Left />

            </div>


        </div>




    )
}

export default ListEvenement;
