import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Assure d'importer le CSS pour les styles par défaut

const SkeletonLoaderPost = () => {
    return (
        <div className="right p-2 mx-2 my-2">
            <div className='d-flex my-2'>
                {/* Skeleton pour le titre "Posts" */}
                <div className='px-2 txt-head'>
                    <Skeleton width={60} height={20} />
                </div>
                {/* Skeleton pour le bouton d'ajout */}
                <div className='px-2 btn-add'>
                    <Skeleton circle={true} width={30} height={30} />
                </div>
            </div>

            {/* Skeleton pour le carousel */}
            <div className="carousel carousel-dark slide">
                <div className="carousel-inner">
                    {[1, 2, 3].map((_, chunkIndex) => (
                        <div key={chunkIndex} className={`carousel-item ${chunkIndex === 0 ? 'active' : ''}`}>
                            <div className="card-group grip gap-2">
                                {[1, 2, 3].map((_, postIndex) => (
                                    <div key={postIndex} className="g-0 card border1 rounded" style={{ minWidth: '30vw', maxWidth: '100vw', maxHeight: '30vh' }}>
                                        <div className='d-flex'>
                                            {/* Skeleton pour l'image du post */}
                                            <Skeleton width={120} height={100} />
                                            <div className='w-100 px-2'>
                                                {/* Skeleton pour le titre du post */}
                                                <Skeleton width={200} height={20} />
                                                {/* Skeleton pour la description du post */}
                                                <Skeleton count={2} height={15} style={{ marginTop: '10px', marginBottom: '10px' }} />
                                                {/* Skeleton pour le footer (date et icônes) */}
                                                <div className="w-100 d-flex justify-content-between footer">
                                                    <Skeleton width={80} height={20} />
                                                    <div className='d-flex'>
                                                        <Skeleton width={15} height={15} circle={true} />
                                                        <Skeleton width={15} height={15} circle={true} style={{ marginLeft: '10px' }} />
                                                        <Skeleton width={15} height={15} circle={true} style={{ marginLeft: '10px' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Skeleton pour les indicateurs du carousel */}
                <div className="carousel-indicators">
                    {[1, 2, 3].map((_, index) => (
                        <Skeleton key={index} circle={true} width={10} height={10} style={{ margin: '0 5px' }} />
                    ))}
                </div>
            </div>

            {/* Skeleton pour le lien "Afficher tous les posts" */}
            <div className="d-flex w-100 justify-content-center">
                <Skeleton width={150} height={20} />
            </div>
        </div>
    );
};

export default SkeletonLoaderPost;
