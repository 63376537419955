

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import './PostDetail.css'
import Left from "../Accueil/Left";
import { useParams } from 'react-router-dom'
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import comment from '../image/icon-card/commentaire.png'
import aime from '../image/icon-card/aime.png'
import aimepas from '../image/icon-card/pas-aime.png'
import aime1 from '../image/comme 1.png'
import PostForm from './AddPost';
// import non_aime from '../image/icon-card/pas-aime.png'
import { useNavigate } from 'react-router-dom';
import SkeletonLoaderPost from '../SkeletonLoad/skeletonloaderPost';
function PostDetail() {
    const idOrganisation = JSON.parse(localStorage.getItem("organisation"))?.id;
    const { id } = useParams();
    const [post, setPost] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(false);
    const [commentaire, setCommentaire] = useState('');
    const [comments, setComments] = useState([]);
    const [isComments, setIsComments] = useState(false);
    const [editCommentId, setEditCommentId] = useState(null);
    const [editCommentContent, setEditCommentContent] = useState('');
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    
    const [rows, setRows] = useState(1);
    const textareaRef = useRef(null);
    const navigate = useNavigate();
    // const [postToEdit, setPostToEdit] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const handleChange = (e) => {
        const textareaLineHeight = 24; // Hauteur approximative d'une ligne dans la textarea
        const previousRows = e.target.rows;

        e.target.rows = 1; // Réinitialise le nombre de lignes à 1 pour recalculer

        const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            e.target.rows = currentRows;
        }

        setRows(currentRows);
        
        setCommentaire(e.target.value);
    };

    const fetchPostData = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/posts/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setPost(response.data);
            setComments(response.data.commentaires); // Set initial comments
            setLoading(false);
        } catch (error) {
            console.error('Error fetching posts:', error);
            setLoading(false);
        }
    };

    const handlePostUpdated = (updatedPost) => {
        setPost(updatedPost);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading1(true);

        const accessToken = localStorage.getItem('accessToken');
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/posts/${id}/comment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                contenu: commentaire
            })
        }).then(response => response.json())
            .then(data => {
                console.log('SuccessComment:', data);
                setLoading1(false);
                setCommentaire(''); // Clear the textarea
                fetchPostData(); // Fetch updated post data including new comment
            })
            .catch((error) => {
                console.error('ErrorComment:', error);
                setLoading1(false);
            });
    };

    const handleDeletePost = async () => {
        // e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/posts/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            fetchPostData(); // Refresh comments after deletion
            navigate('/');
            console.log('text');

        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    const handleDeleteComment = async (commentId) => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/posts/${id}/comment/${commentId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            fetchPostData(); // Refresh comments after deletion
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    const handleEditComment = (commentId, content) => {
        setEditCommentId(commentId);
        setEditCommentContent(content);
    };

    const handleSaveEditComment = async (commentId) => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/posts/${id}/comment/${commentId}`, {
                contenu: editCommentContent
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setEditCommentId(null);
            fetchPostData(); // Refresh comments after editing
        } catch (error) {
            console.error('Error editing comment:', error);
        }
    };

    const handleCancelEditComment = () => {
        setEditCommentId(null);
        setEditCommentContent('');
    };

    const likes = (e) => {
        console.log(e);
        const accessToken = localStorage.getItem('accessToken');
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/posts/${id}/reaction/${e}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({})
        }).then(response => response.json())
            .then(data => {
                console.log('SuccessLike&Dislike:', data);
                fetchPostData(); // Fetch updated post data including new comment
            })
            .catch((error) => {
                console.error('ErrorComment:', error);
            });
    };

    useEffect(() => {
        fetchPostData();

    }, [id]);


    if (loading) {
        return (
            <SkeletonLoaderPost />
        );
    }

    return (
        <div className="row m-1">
            <div className='col-2'></div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">

                <div className="right p-2 mx-md-2 my-2" key={post.id}>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex pb-2'>
                            <div className=''>
                                <img src={FILE_URL + post.organisation.logo} className='image-comment rounded-circle' alt='logo organisation' />
                            </div>

                            <div className=''>
                                <div className='non-publica-style '>
                                    {post.organisation.nom}
                                </div>
                                <div className='date-publica-style'>
                                    {new Date(post.createdAt).toLocaleDateString()}
                                </div>
                            </div>
                        </div>

                        {
                            idOrganisation === post.organisation.id && (<div className='dropdown'>
                                <button className="btn-non-border-style" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FontAwesomeIcon icon={faEllipsisVertical} className='font-icon' />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    {/* <li><Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#addpostModal" onClick={() => setIsEditMode(true)}> Modifier </Link></li> */}

                                    <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#addpostModal" onClick={() => setIsEditMode(true)}><FontAwesomeIcon icon={faPenToSquare} className='font-icon px-2' /> Modifier</button></li>
                                    <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#suppostModal"><FontAwesomeIcon icon={faTrashCan} className='font-icon px-2' /> Supprimer</button></li>

                                </ul>

                                <div className="modal fade" id="suppostModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className=" modal-dialog modal-dialog-centered ">
                                        <div className="modal-content">
                                            <div className="modal-header">

                                                <h5 className='modal-title fs-4'>Supprimer le post</h5>

                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">

                                                <div className='text-center'>

                                                    <p>Vous êtes sur le point de supprimer ce post. Voulez-vous continuer?</p>


                                                </div>

                                                <div className='d-flex justify-content-between align-item-center'>
                                                    <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">Non</button>
                                                    <button className="btn btn-success px-3" type="button" onClick={() => handleDeletePost()} data-bs-dismiss="modal"> Oui</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }



                    </div>
                    <div className="px-2 pt-1 text-justify-style pb-2">

                        {post.description}
                    </div>
                    <div className="couvercle w-100">
                        <img src={FILE_URL + post.couverture} className='image-detail-post' alt="..." />
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='text-comment d-flex'>
                            <div><img src={aime1} width="20" alt="likes" /></div>
                            <div className='text-comment1'>{post._count.likes}</div>
                        </div>
                        <div className='text-comment' onClick={() => setIsComments(true)}>
                            <span>{post._count.commentaires}</span> Commentaires
                        </div>
                    </div>
                    <div className="px-2 menu-post-style">
                        <div className='d-flex justify-content-between my-1'>

                            <button className='btn-non-border-style mx-1 d-flex' onClick={() => setIsComments(true)}>
                                <div className='' >
                                    <img src={FILE_URL + JSON.parse(localStorage.getItem('account')).photo} className='rounded-circle' width='30' height='30' alt="comments" />
                                </div>


                            </button>
                            <button className='btn-non-border-style mx-1 d-flex' onClick={() => setIsComments(true)}>
                                <div className='' >
                                    <img src={comment} width="25" alt="comments" />
                                </div>
                                <div className='icon-txt px-1'>
                                    Commenter
                                </div>

                            </button>

                            <div className='d-flex'>
                                <button className='btn-non-border-style mx-1 d-flex' onClick={() => likes('like')}>
                                    <div className='px-1'>
                                        <img src={aime} width="25" alt="likes" />
                                    </div>

                                </button>
                                <button className='btn-non-border-style mx-1 d-flex' onClick={() => likes('dislike')}>
                                    <div className='px-1'>
                                        <img src={aimepas} width="25" alt="likes" />
                                    </div>

                                </button>
                            </div>



                        </div>


                    </div>
                </div>


                {
                    (isComments) && <div className="right p-2 mx-2 my-2 d-flex justify-content-center">
                        <div className="form" key={post.id}>
                            <form onSubmit={handleSubmit} className='d-flex'>
                                <textarea
                                    onChange={handleChange}
                                    value={commentaire}
                                    ref={textareaRef}
                                    rows={rows}
                                    cols="80"
                                    className='comment-style'
                                    placeholder='Ecrivez votre commentaire ici..'>
                                </textarea>
                                {commentaire.trim() !== '' && (
                                    <div className="">
                                        <button className='btn-3 mx-1' type="submit" disabled={loading1}>
                                            {loading1 ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Commenter'}
                                        </button>
                                    </div>
                                )}
                            </form>
                            {comments.map(comment => (
                                <div key={comment.id} className='border rounded mt-2 bg-secondar1 w-100'>
                                    <div className='d-flex'>
                                        <div>
                                            {
                                                (comment.commentePar.photo) ? <img src={FILE_URL + comment.commentePar.photo} className='image-comment rounded-circle' alt="..." /> : ''
                                            }
                                        </div>
                                        <div className='px-2 me-auto'>
                                            <h6 className="pt-2">
                                                {comment.commentePar.nom} {comment.commentePar.prenom}
                                            </h6>
                                            <div className="comment-contenu">
                                                {editCommentId === comment.id ? (
                                                    <>
                                                        <textarea
                                                            autofocus='true'
                                                            value={editCommentContent}
                                                            onChange={(e) => setEditCommentContent(e.target.value)}

                                                            cols="80"
                                                            className='commentedit-style'

                                                        />
                                                        <div className="d-flex justify-content-start">
                                                            <button className='btn4 mx-2' onClick={() => handleSaveEditComment(comment.id)}>Enregistrer</button>
                                                            <button className='btn4-anuler' onClick={handleCancelEditComment}>Annuler</button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    comment.contenu
                                                )}
                                            </div>
                                        </div>
                                        {comment.commentePar.id === JSON.parse(localStorage.getItem('account')).id && (
                                            <div className='dropstart'>
                                                <button className="btn-non-border-style" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <FontAwesomeIcon icon={faEllipsisVertical} className='font-icon' />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li><button className="dropdown-item" type="button" onClick={() => handleEditComment(comment.id, comment.contenu)}><FontAwesomeIcon icon={faPenToSquare} className='font-icon px-2' /> Modifier</button></li>
                                                    <li><button className="dropdown-item" type="button" onClick={() => handleDeleteComment(comment.id)}><FontAwesomeIcon icon={faTrashCan} className='font-icon px-2' /> Supprimer</button></li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }

                <PostForm postToEdit={post} isEditMode={isEditMode} onPostAdded={() => {/* Rafraîchir la liste des posts */ }} onPostUpdated={handlePostUpdated} />
            </div>
            <div className="col-4">
                <Left />
            </div>
        </div>
    );
}

export default PostDetail;