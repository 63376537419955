

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import logo_dusite from '../image/logo-site/image.jpg'; // Assurez-vous de mettre le bon chemin vers votre logo

function StatusForm() {
    const idOrganisation = localStorage.getItem('organisation');
    const selectedItem = localStorage.getItem('selectedItem');
    const navigate = useNavigate();

    const [description, setDescription] = useState('');
    const [media, setMedia] = useState(null);
    const [preview, setPreview] = useState(null);
    const [timer, setTimer] = useState(24); // Default to 24H
    const [backgroundColor, setBackgroundColor] = useState('#2F6195'); // Default to white
    const [statusType, setStatusType] = useState('compte');
    const [showFormText, setShowFormText] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);

    useEffect(() => {
        if (idOrganisation) {
            setStatusType('organisationId')
        } else {
            setStatusType('compte')
        }
    }, [idOrganisation]);

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleMediaChange = (e) => {
        const file = e.target.files[0];
        setMedia(file);
        setPreview(URL.createObjectURL(file));
    };

    const handleTimerChange = (e) => {
        setTimer(e.target.value);
    };

    const handleBackgroundColorChange = (e) => {
        setBackgroundColor(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (!description) {
        //     alert("La description est obligatoire.");
        //     return;
        // }
        const formData = new FormData();
        formData.append(statusType === 'compte' ? 'compteId' : 'organisationId', selectedItem);
        formData.append('media', media);
        formData.append('description', description);
        formData.append('timer', timer);
        formData.append('color', backgroundColor);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/stories`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            console.log('Status created successfully:', response.data);
            setShowSuccessModal(true);
            setMedia(null)
        } catch (error) {
            console.error('Error creating status:', error);
            setMedia(null)
        }
    };

    const handleCreateStatusClick = () => {
        setShowFormText(true);
        setShowInput(true);
    };

    const handleCancelClick = () => {
        setShowCancelModal(true);
    };

    const handleConfirmCancel = () => {
        setShowFormText(false);
        setShowCancelModal(false);
    };

    const handleRedirectHome = () => {
        navigate('/');
    };

    return (
        <div className="m-2">
            {showFormText ? (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-3 mt-2">
                            <form onSubmit={handleSubmit} className="formulaire-style">
                                {showInput ? (
                                    <>
                                        <div className="mb-1">
                                            <div className="d-flex align-item-center justify-content-center w-100">
                                                <label htmlFor="media" className="d-flex flex-column align-item-center justify-content-center">
                                                    <div className="d-flex flex-column align-item-center justify-content-center">
                                                        <svg className="w-100 h-75 mb-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                        </svg>
                                                        <p className="mb-1 text-center"><span className="font-semibold">Cliquez pour télécharger</span> ou glisser-déposer</p>
                                                        <p className="text-center">SVG, PNG, JPG or GIF</p>
                                                    </div>
                                                    <input id="media" type="file" className="hidden" onChange={handleMediaChange} style={{ display: 'none' }} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="timer" className="form-label">Timer</label>
                                            <select id="timer" className="form-select" value={timer} onChange={handleTimerChange}>
                                                <option value={24}>24H</option>
                                                <option value={48}>48H</option>
                                                <option value={72}>72H</option>
                                            </select>
                                        </div>
                                    </>

                                ) : (
                                    <div>
                                        <div className="mb-3">
                                            <label htmlFor="description" className="form-label">Description</label>
                                            <textarea
                                                id="description"
                                                className="form-control"
                                                value={description}
                                                onChange={handleDescriptionChange}
                                                rows="3"

                                            ></textarea>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="backgroundColor" className="form-label">Couleur de fond</label>
                                            <input
                                                type="color"
                                                id="backgroundColor"
                                                className="form-control colo-input-style"
                                                value={backgroundColor}
                                                onChange={handleBackgroundColorChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="timer" className="form-label">Timer</label>
                                            <select id="timer" className="form-select" value={timer} onChange={handleTimerChange}>
                                                <option value={24}>24H</option>
                                                <option value={48}>48H</option>
                                                <option value={72}>72H</option>
                                            </select>
                                        </div>
                                    </div>
                                )}
                                <div className='d-flex justify-content-between buton-botom'>
                                    <button type="button" className="btn btn-secondary mx-2" style={{ width: '100vw' }} onClick={handleCancelClick}>Annuler</button>
                                    <button type="submit" className="btn btn-primary mx-2" style={{ width: '100vw' }}>Publier</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-md-8 col-lg-9 vsiualisation mt-2">
                            <h6>Aperçu</h6>
                            <div className="sous-contenu">
                                <div className="preview" style={{ backgroundColor: backgroundColor, padding: '20px', borderRadius: '10px' }}>
                                    {preview ? (
                                        <img src={preview} alt="Prévisualisation" className="prewiew-image" />
                                    ) : (
                                        showInput && (
                                            <img src={logo_dusite} alt="Logo du site" className="prewiew-image" />
                                        )
                                    )}
                                    {!showInput && (
                                        <div className="preview-description" style={{ color: '#000' }}>
                                            {description}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column flex-lg-row justify-content-center">
                    <Link className="card-button-create-status" onClick={handleCreateStatusClick}>
                        <div className="icon-circulor">
                            <FontAwesomeIcon icon={faImages} />
                        </div>
                        <div className="icon-circulor1 text-light">
                            Créer un statut photo
                        </div>
                    </Link>
                    <Link className="card-button-create-status" onClick={() => { setShowFormText(true); setMedia(null) }}>
                        <div className="icon-circulor">Aa</div>
                        <div className="icon-circulor1 text-light">
                            Créer un statut de texte
                        </div>
                    </Link>
                </div>
            )}

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="modal show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Succès</h5>
                                <button type="button" className="btn-close" onClick={() => setShowSuccessModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Le statut a été créé avec succès.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" style={{ width: '20vw' }} onClick={handleRedirectHome}>Retour à l'accueil</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Cancel Confirmation Modal */}
            {showCancelModal && (
                <div className="modal show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirmation</h5>
                                <button type="button" className="btn-close" onClick={() => setShowCancelModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Voulez-vous vraiment abandonner ?</p>
                            </div>
                            <div className="modal-footer d-flex flex-md-row">
                                <button type="button" className="btn btn-secondary" style={{ width: '10vw' }} onClick={() => setShowCancelModal(false)}>Non</button>
                                <button type="button" className="btn btn-primary" style={{ width: '10vw' }} onClick={() => { handleConfirmCancel(); setShowInput(false); setPreview(null) }}>Oui</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default StatusForm;