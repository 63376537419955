/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import add from '../image/icon-card/add.png'

import interested from '../image/icon-card/interested.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faEnvelope, faEye, faGlobe, faLocationDot, faPen, faPhoneAlt, faShare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Left from '../Accueil/Left'
import ProjetForm from './AddProjet';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import logo_site from '../image/logo-site/image.jpg'
import { toast, ToastContainer, Zoom } from 'react-toastify';
function ProjetDetail() {

    const idOrganisation = JSON.parse(localStorage.getItem("organisation"))?.id;
    const { id } = useParams();
    const [projet, setProjet] = useState([]);
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();
    const [idProject, setIdProject] = useState('');
    const [isInterested, setIsInterested] = useState(false);

    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const fetchData = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/projects/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setProjet(response.data);
            console.log(response.data)
            response.data.intereses.map(
                (interes) => {
                    if (interes.compte.id === JSON.parse(localStorage.getItem("account"))?.id) {
                        setIsInterested(true);
                    }else{
                        setIsInterested(false);
                    }
                }
            )
            setLoading(false);
        } catch (error) {
            console.error('Error fetching posts:', error);
            setLoading(false);
        }
    };


    const handleProjetUpdated = (updatedProjet) => {
        setProjet(updatedProjet);
    };

    const handleDeleteProjet = async () => {
        // e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/projects/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            fetchData();
            navigate('/');
            console.log('text');

        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };



    const interProjet = (e) => {
        const accessToken = localStorage.getItem('accessToken');
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/projects/${e}/interest`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(response => response.json())
            .then(data => {
                console.log('Success partage carte:', data);
                fetchData();
                notify("Votre carte est partagé avec succès !");
                // setLoading(false);
            })
            .catch((error) => {
                console.error('ErrorPartage:', error);
                notify("Erreur lors du partage de votre carte !", 'error');
                // setLoading(false);
            });

    }
    useEffect(() => {


        fetchData();
    }, [id]);


    if (loading) {
        return (
            <div className="row m-1">
                <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                    <div className="right p-2 mx-2 my-2">
                        <Skeleton height={200} className="mb-2" />
                        <Skeleton height={30} width={150} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                    </div>
                </div>
                <div className="col-4">
                    <Skeleton height={200} className="mb-2" />
                    <Skeleton height={200} className="mb-2" />
                </div>
            </div>
        );
    }
    return (
        <div className="row m-1">
            <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                <div className="right p-2 mx-2 pb-0">

                    {
                        projet.id && <div key={projet.id} className="g-0 rounded p-2 pb-0">
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex pb-2'>
                                    <div className=''>
                                        <img src={FILE_URL + projet.organisation?.logo} className='image-comment rounded-circle' alt='logo organisation' />
                                    </div>

                                    <div className=''>
                                        <div className='non-publica-style '>
                                            {projet.organisation?.nom}
                                        </div>
                                        <div className='date-publica-style'>
                                            {new Date(projet.createdAt).toLocaleDateString()}
                                        </div>
                                    </div>
                                </div>


                                <div className='dropdown'>
                                    <button className="btn-non-border-style" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FontAwesomeIcon icon={faEllipsisVertical} className='font-icon' />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        {/* <li><Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#addpostModal" onClick={() => setIsEditMode(true)}> Modifier </Link></li> */}
                                        {
                                            idOrganisation === projet.organisation?.id && <>
                                                <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#addProjetModal" onClick={() => setIsEditMode(true)}><FontAwesomeIcon icon={faPenToSquare} className='font-icon px-2' /> Modifier</button></li>
                                                <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#supprojetModal"><FontAwesomeIcon icon={faTrashCan} className='font-icon px-2' /> Supprimer</button></li>

                                            </>
                                        }

                                        <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#supprojetModal"><img src={interested} className='font-icon px-1' alt='...' /> Intéresser</button></li>


                                    </ul>

                                    <div className="modal fade" id="supprojetModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className=" modal-dialog modal-dialog-centered ">
                                            <div className="modal-content">
                                                <div className="modal-header">

                                                    <h5 className='modal-title fs-4'>Supprimer le post</h5>

                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">

                                                    <div className='text-center'>

                                                        <p>Vous êtes sur le point de supprimer ce post. Voulez-vous continuer?</p>


                                                    </div>

                                                    <div className='d-flex justify-content-between align-item-center'>
                                                        <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">Non</button>
                                                        <button className="btn btn-success px-3" type="button" onClick={() => handleDeleteProjet()} data-bs-dismiss="modal"> Oui</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <h5 className='prj-title'>
                                {projet.titre}
                            </h5>
                            <div className='prj-desc'>
                                {projet.description}
                            </div>

                            <div className='d-flex flex-column flex-md-row justify-content-between mt-3'>
                                <div className='budget mt-3'>
                                    <h6> Budget</h6>
                                    <div>

                                        <span>Maxi: {projet.max}</span>
                                        <span>Min : {projet.min}</span>

                                    </div>
                                </div>

                                <div className='budget mt-3'>
                                    <h6> Deadline</h6>
                                    <div>
                                        <span>{new Date(projet.date).toLocaleDateString()}</span>
                                    </div>

                                </div>
                            </div>

                            <div className='px-2 py-3 d-flex flex-md-row align-items-center justify-content-end w-100'>


                                {isInterested ? <button className='prj-btn2 d-flex justify-content-center' onClick={() => setIdProject(projet.id)} data-bs-toggle="modal" data-bs-target="#InterestedModal" >
                                    <img src={interested} width="25px" alt='...' /> <span className='txt-btn px-1'>
                                        Désintéresser
                                    </span>
                                </button> : <button className='prj-btn1 d-flex justify-content-center' onClick={() => setIdProject(projet.id)} data-bs-toggle="modal" data-bs-target="#InterestedModal" >
                                    <img src={interested} width="25px" alt='...' /> <span className='txt-btn px-1'>
                                        Intéresser
                                    </span>
                                </button>}


                            </div>

                        </div>
                    }


                </div>
                <div className="right p-2 mx-2 my-2 pb-0">
                    <h4 className='my-3'>
                        Liste des intéressé(e)s
                    </h4>
                    <div class="container">
                        <div class="row">
                            {
                                
                                Array.isArray(projet.intereses) && projet.intereses.lenght !== 0 ? projet.intereses.map(cards => (
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-4" key={cards.compte.id}>
                                        <div class="our-team">
                                            <div className='d-flex justify-content-end'>
                                                <div class="dropdown dropdown1">
                                                    <button class="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {/* <img src={seting} width="25px" alt='..' /> */}
                                                        <FontAwesomeIcon icon={faEllipsisVertical} className='icon' />
                                                    </button>
                                                    <ul class="dropdown-menu dropdown-menu-white">
                                                        <Link class="nav-link" to={`/cartes-detail/${cards.compte.id}`}>
                                                            <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >
                                                                {/* <img src={detail} className='icon-seting' alt='...' /> */}
                                                                <FontAwesomeIcon icon={faEye} className='icon-seting' />
                                                                Detail
                                                            </li>
                                                        </Link>
                                                        <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' onClick={() => {
                                                            //handleType('compte');
                                                            //handleTypeId(count.id);
                                                            setIsEditMode(true);
                                                            //handleSetCart(cards.id)

                                                        }} data-bs-toggle="modal" data-bs-target="#addCarteModal"  >

                                                            <FontAwesomeIcon icon={faPen} className='icon-seting' />
                                                            <Link class="nav-link" to="#">Modifier</Link>
                                                        </li>

                                                        <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' onClick={() => {
                                                            //handleType('compte');
                                                            //handleTypeId(count.id)

                                                        }} data-bs-toggle="modal" data-bs-target="#supCarteModal" >

                                                            <FontAwesomeIcon icon={faTrashCan} className='icon-seting' />
                                                            <Link class="nav-link" to="#">Supprimer</Link>
                                                        </li>


                                                    </ul>
                                                </div>
                                                <div className="modal fade" id="supCarteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className=" modal-dialog modal-dialog-centered ">
                                                        <div className="modal-content">
                                                            <div className="modal-header">

                                                                <h5 className='modal-title fs-4'>Supprimer la Carte</h5>

                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">

                                                                <div className='text-center'>

                                                                    <p>Vous êtes sur le point de supprimer une carte. Voulez-vous continuer?</p>


                                                                </div>

                                                                <div className='d-flex justify-content-between align-item-center'>
                                                                    <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">Non</button>
                                                                    <button className="btn btn-success px-3" type="button" data-bs-dismiss="modal"> Oui</button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="picture">
                                                {
                                                    cards.photo ? <img src={FILE_URL + cards.photo} className='img-fluid' alt='..' /> : <img src={logo_site} className='img-fluid' alt='..' />
                                                }
                                                {/* <img class="img-fluid" src="https://picsum.photos/130/130?image=1027" /> */}
                                            </div>
                                            <div class="team-content">
                                                <h5 class="name">{cards.compte.prenom} {cards.compte.nom}</h5>
                                                <h4 class="title text-truncate" >{cards.compte.profession}</h4>

                                            </div>

                                            <div class="social">

                                                <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.compte.adresse}`}>
                                                    <FontAwesomeIcon icon={faLocationDot} className='icon' />
                                                </button>
                                                <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.compte.site}`}>
                                                    <FontAwesomeIcon icon={faGlobe} className='icon' />
                                                </button>
                                                <button type="button" class="" data-bs-container="body" key={cards.compte.emails[0].id} data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.compte?.emails[0]?.email}`}>
                                                    <FontAwesomeIcon icon={faEnvelope} className='icon' />
                                                </button>
                                                <button type="button" class="" data-bs-container="body" key={cards.compte?.phones[0].id} data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.compte?.phones[0]?.phone}`}>
                                                    <FontAwesomeIcon icon={faPhoneAlt} className='icon' />
                                                </button>


                                            </div>
                                        </div>
                                    </div>
                                )) : <div className='text-center'>Aucun intéressé(e) pour le moment</div>
                            }


                        </div>
                    </div>
                </div>

                <div class="modal fade" id="InterestedModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header d-flex justify-content-between p-2">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Êtes vous vraiment Intéresser ?</h1>
                                <button type="button" className='button-close-style' data-bs-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={faXmark} />
                                </button>
                            </div>
                            <div class="modal-body">
                                {/* <h3> Êtes vous vraiment Intéresser </h3> */}
                                <button type="button" className='partager-button-style' data-bs-dismiss="modal" aria-label="Close" onClick={() => interProjet(idProject)}>
                                    <FontAwesomeIcon icon={faShare} /> &nbsp;
                                    Partagez nous votre carte
                                </button>
                            </div>

                        </div>
                    </div>
                </div>



                <ProjetForm projetToEdit={projet} isEditMode={isEditMode} onProjetAdded={() => {/* Rafraîchir la liste des posts */ }} onProjetUpdated={handleProjetUpdated} />

            </div>

            <div className="col-4">
                <Left />

            </div>

        </div>
    )
}

export default ProjetDetail;

