/* eslint-disable jsx-a11y/anchor-is-valid */
import add from '../image/icon-card/add.png'
import budget from '../image/icon-card/budget.png'
import deadline from '../image/icon-card/deadline.png'
import interested from '../image/icon-card/interested.png'

import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProjetForm from '../Projet/AddProjet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SkeletonLoaderPost from '../SkeletonLoad/skeletonloaderPost'
function ProjetAccueil() {
    const [projets, setProjets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [idProject, setIdProject] = useState('');
    const [organisation, setOrganisation] = useState(JSON.parse(localStorage.getItem('organisation')) || '');
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    useEffect(() => {
        // Définir un intervalle qui vérifie la valeur dans le localStorage toutes les secondes
        const intervalId = setInterval(() => {
          const currentOrganisation = localStorage.getItem('organisation') || '';
          if (currentOrganisation !== organisation) {
            setOrganisation(currentOrganisation);
          }
        }, 1000); // Intervalle de 1 seconde
    
        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
      }, [organisation]); // Dépendance à l'état "organisation"

    
      const VerificatioCompte = (e) =>{

        if(!organisation){
            notify('Veillez changer de compte pour pouvoir créer un projet','error')
        }else{
            // Ouvre le modal Bootstrap pur avec jQuery ou JavaScript pur
            const scanModal = document.getElementById('addProjetModal');
            const bootstrapModal = new window.bootstrap.Modal(scanModal);
            bootstrapModal.show();
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/projects`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                // Trier les posts par date de création (du plus récent au plus ancien)
                const sortedProjets = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                // Sélectionner les six derniers posts
                const latestProjets = sortedProjets.slice(0, 6);
                setProjets(latestProjets);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching posts:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    const handleProjetAdded = (newData) => {
        setProjets([newData, ...projets]);
    };

    const interProjet = (e) => {
        const accessToken = localStorage.getItem('accessToken');
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/projects/${e}/interest`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(response => response.json())
            .then(data => {
                console.log('Success partage carte:', data);
                notify("Votre carte est partagé avec succès !");
                // setLoading(false);
            })
            .catch((error) => {
                console.error('ErrorPartage:', error);
                notify("Erreur lors du partage de votre carte !", 'error');
                // setLoading(false);
            });

    }
    const chunkedPosts = chunkArray(projets, 2);
    
    if (loading) {
        return (
            <SkeletonLoaderPost />
        );
    }else if(projets.length === 0){
        return (
            <div className="right p-2 mx-2 my-2 h-25  position-relative">
                <div className='d-flex my-2'>
                    <div className='px-2 txt-head'>
                        Projets
                    </div>
                    <div className='px-2 btn-add' onClick={VerificatioCompte}>
                        <img src={add} class="rounded-start flex-shrink-1" alt="..." />
                    </div>

                </div>
                <div className=' w-100 h-75 bg-body-secondary'>

                    <h6 className='position-absolute top-50 start-50 translate-middle text-center'>Aucun Projet disponible</h6>

                </div>

                <ProjetForm onProjetAdded={handleProjetAdded} />

            </div>
        )
    }
    return (
        <>
            <div className="right p-2 mx-2 my-2">
                <div className='d-flex my-2'>
                    <div className='px-2 txt-head'>
                        Projets
                    </div>
                    <div className='px-2 btn-add' onClick={VerificatioCompte}>
                        <img src={add} class="rounded-start flex-shrink-1" alt="..." />
                    </div>

                </div>
                <div id="carouselProjet" class="carousel carousel-dark slide" data-bs-ride="true">
                    <div className="carousel-inner">
                        {chunkedPosts.map((chunk, chunkIndex) => (
                            <div key={chunkIndex} className={`carousel-item ${chunkIndex === 0 ? 'active' : ''}`}>
                                <div className="card-group grip gap-2 section-container">
                                    {chunk.map(projet => (
                                        <div key={projet.id} className="g-0 border1 rounded p-2" style={chunkedPosts.length === 1 || chunk.length === 1 ? { maxWidth: '28vw' } : {minWidth: '20em', maxWidth: '30vw' }}>
                                            <h5 className='prj-title text-truncate' style={{ maxWidth: '20vw' }}>
                                                {projet.titre}
                                            </h5>
                                            <div className='prj-desc description'>
                                                {projet.description}
                                            </div>
                                            <div className='px-2 py-2 d-flex justify-content-start w-100'>
                                                <img src={budget} alt='...' />
                                                <div className='px-2 txt-bold text-truncate' style={{ maxWidth: '100vw' }}>
                                                    <span className='txt-bd'>Budget :</span>  Maxi: {projet.max} Min : {projet.min}
                                                </div>
                                            </div>
                                            <div className='px-2 d-flex justify-content-start w-100'>
                                                <img src={deadline} alt='...' />
                                                <div className='px-2 txt-bold'>
                                                    <span className='txt-bd'>deadline : </span>  {new Date(projet.date).toLocaleDateString()}
                                                </div>
                                            </div>

                                        <div className='px-2 py-3 d-flex flex-md-row align-items-center justify-content-between w-100'>
                                                <button className='prj-btn1 d-flex justify-content-center' onClick={() => setIdProject(projet.id)} data-bs-toggle="modal" data-bs-target="#InterestedModal" >
                                                    <img src={interested} width="25px"  alt='...' /> <span className='txt-btn px-1'>Intéresser</span>
                                                </button>
                                                <button className='prj-btn1 my-2 my-md-0'>
                                                    <Link to={`/projet-detail/${projet.id}`} className='nav-link'>

                                                        voir detail
                                                    </Link>
                                                </button>

                                            </div>
                                            
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    
                    <div className="carousel-indicators">
                        {chunkedPosts.map((chunk, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselProjet"
                                data-bs-slide-to={index}
                                className={index === 0 ? 'active' : ''}
                                aria-current={index === 0 ? 'true' : 'false'}
                                aria-label={`Slide ${index + 1}`}
                            ></button>
                        ))}
                    </div>
                </div>

                <div class="d-flex w-100 justify-content-center">
                {
                        projets.length > 2 ? (
                            <div className="d-flex w-100 justify-content-center">
                                <Link to={`/projet-list`} className='nav-link'>Afficher tous les projets &rarr;</Link>
                            </div>
                        ) : null
                    }
                </div>

            </div>
            <div class="modal fade" id="InterestedModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header d-flex justify-content-between p-2">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Êtes vous vraiment Intéresser ?</h1>
                            <button type="button" className='button-close-style'  data-bs-dismiss="modal" aria-label="Close">
                            <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>
                        <div class="modal-body">
                            {/* <h3> Êtes vous vraiment Intéresser </h3> */}
                            <button type="button" className='partager-button-style' data-bs-dismiss="modal" aria-label="Close" onClick={() => interProjet(idProject)}>
                            <FontAwesomeIcon icon={faShare} /> &nbsp;
                                Partagez nous votre carte
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
            <ToastContainer transition={Zoom} />
            <ProjetForm onProjetAdded={handleProjetAdded} />
        </>
    )
}

export default ProjetAccueil;

// Utility function to chunk the array
const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
};