import Left from "../Accueil/Left";
import './Abonnement.css'
import start from '../image/Abonnement/start.png'
function Abonnement() {
    return (
        <div className="row m-md-2">
            <div className="col-12 col-md-8 col-lg-8">
                <div className="right p-2 mx-1 my-2">

                    <div class="nav d-flex justify-content-center nav-pills mx-md-2 " id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button class="nav-link active butn butn2" id="v-pills-organisation-tab" data-bs-toggle="pill" data-bs-target="#v-pills-organisation" type="button" role="tab" aria-controls="v-pills-organisation" aria-selected="true">Plans Organisation</button>
                        {/* <button class="nav-link butn1" id="v-pills-RH-tab" data-bs-toggle="pill" data-bs-target="#v-pills-RH" type="button" role="tab" aria-controls="v-pills-RH" aria-selected="false">RH</button> */}
                        <button class="nav-link butn butn2" id="v-pills-Compte-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Compte" type="button" role="tab" aria-controls="v-pills-Compte" aria-selected="false">Plans Compte</button>
                    </div>

                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane px-5 justify-content-center fade show" id="v-pills-Compte" role="tabpanel" aria-labelledby="v-pills-Compte-tab" tabindex="0">
                            <div className="plan-organisation">

                                <div className="PlanGreen rounded border p-3 my-3">
                                    <h6>Plan Green</h6>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Durée 12 mois
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création de 20 cartes maximum
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            2 Publications de statuts par semaine
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création d'une organisation maximum
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            <span className="txt-bold">Prix:</span> gratuit
                                        </div>
                                    </div>
                                    <div className='pt-3 d-flex justify-content-center w-100'>
                                        <button className="btn-green px-3">
                                            S'abonner
                                        </button>
                                    </div>



                                </div>

                                <div className="PlanSilver rounded border p-3 my-3">
                                    <h6>Plan Silver</h6>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Durée 12 mois
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création de 200 cartes maximum
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            7 Publications de statuts par semaine
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création de 2 organisation maximum
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            <span className="txt-bold">Prix:</span> 6 500 FCFA
                                        </div>
                                    </div>
                                    <div className='pt-3 d-flex justify-content-center w-100'>
                                        <button className="btn-silver px-3">
                                            S'abonner
                                        </button>
                                    </div>



                                </div>

                                <div className="PlanGold rounded border p-3 my-3">
                                    <h6>Plan Gold</h6>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Durée 12 mois
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création de cartes illimités
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Publications de statuts illimités
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création de 5 organisation maximum
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            <span className="txt-bold">Prix:</span> 15 000 FCFA
                                        </div>
                                    </div>
                                    <div className='pt-3 d-flex justify-content-center w-100'>
                                        <button className="btn-gold px-3">
                                            S'abonner
                                        </button>
                                    </div>



                                </div>

                            </div>




                        </div>
                        <div class="tab-pane  fade show active " id="v-pills-organisation" role="tabpanel" aria-labelledby="v-pills-organisation-tab" tabindex="0">
                            <div className="plan-organisation">
                                <div className="PlanGreen rounded border p-3 my-3">
                                    <h6>Plan Green</h6>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Durée 12 mois
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création d'une team maximum
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Invitations de 2 personnes à intégrer une organisation
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création de 10 cartes maximum par utilisateur
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            1 Publications de statut par semaine
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            1 Publications de post par semaine
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Non modification de paramètre après création
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            <span className="txt-bold">Prix:</span> gratuit
                                        </div>
                                    </div>
                                    <div className='pt-3 d-flex justify-content-center w-100'>
                                        <button className="btn-green px-3">
                                            S'abonner
                                        </button>
                                    </div>



                                </div>

                                <div className="PlanSilver rounded border p-3 my-3">
                                    <h6>Plan Silver</h6>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Durée 12 mois
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création de team illimité
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Invitations de 10 personnes à intégrer ton organisation gratuitement
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création de 200 cartes maximum par utilisateur
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Réseau de cartes de visite partagé par departement
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            7 Publications de statut par semaine
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            7 Publications de post par semaine
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            1 Publications d'événements par semaine
                                        </div>
                                    </div>

                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            <span className="txt-bold">Prix:</span> 30000
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            <span className="txt-bold">NB:</span> Invitation à intégrer l'organisation au-delà de 10 personnes à 6 500 FCFA par invitation
                                        </div>
                                    </div>
                                    <div className='pt-3 d-flex justify-content-center w-100'>
                                        <button className="btn-silver px-3">
                                            S'abonner
                                        </button>
                                    </div>

                                </div>

                                <div className="PlanGold rounded border p-3 my-3">
                                    <h6>Plan Gold</h6>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Durée 12 mois
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création de team illimité
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Invitations de 25 personnes à intégrer ton organisation gratuitement
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Création de cartes illimité par utilisateur
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Réseau de cartes de visite partagé par departement
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Publications de statut illimité
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Publications de post illimité
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Publications d'événements illimité
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            Presence d'un visuel dans la bannière pendant 12H par mois
                                        </div>
                                    </div>

                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            <span className="txt-bold">Prix:</span> 150 000 FCFA
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={start} width="20px" height="20px" alt='...' />
                                        <div className='px-2'>
                                            <span className="txt-bold">NB:</span> Invitation à intégrer l'organisation au-delà de 10 personnes à 6 500 FCFA par invitation
                                        </div>
                                    </div>
                                    <div className='pt-3 d-flex justify-content-center w-100'>
                                        <button className="btn-gold px-3">
                                            S'abonner
                                        </button>
                                    </div>

                                </div>
                            </div>




                        </div>

                    </div>

                </div>

            </div>
            <div className="col-4">
                <Left />

            </div>
        </div>
    )
}

export default Abonnement;