
import './Left.css'
function Left() {

    return (
        <div className="left1 pb-2">
            {/* <div className='mb-2 p-2 left-head'>
                Publicité

            </div> */}

            <div className=''>
                Publicité
            </div>
            
        </div>
    )

}

export default Left;