
/* eslint-disable jsx-a11y/anchor-is-valid */
import logo_site from '../image/logo-site/logo.png';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import StatusViewer from '../Status/StatusViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SkeletonLoaderPost from '../SkeletonLoad/skeletonloaderPost';
function Status() {
    const [statuts, setStatuts] = useState([]);
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/";
    const [idStatus, setIdStatus] = useState('');
    const account = JSON.parse(localStorage.getItem('account'))
    // const organisation = JSON.parse(localStorage.getItem('organisation'))
    const [organisation, setOrganisation] = useState(JSON.parse(localStorage.getItem('organisation')) || '');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate(); // Ensure navigate is defined
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const [comptesResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/api/v1/stories/comptes`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }),
                    // axios.get('/api/v1/stories/organisations', {
                    //     headers: {
                    //         Authorization: `Bearer ${accessToken}`
                    //     }
                    // })
                ]);
                const combinedStatuts = [...comptesResponse.data];
                console.log(combinedStatuts);
                setStatuts(combinedStatuts);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching posts:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // Définir un intervalle qui vérifie la valeur dans le localStorage toutes les secondes
        const intervalId = setInterval(() => {
            const currentOrganisation = localStorage.getItem('organisation') || '';
            if (currentOrganisation !== organisation) {
                setOrganisation(currentOrganisation);
            }
        }, 1000); // Intervalle de 1 seconde

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, [organisation]); // Dépendance à l'état "organisation"


    const VerificatioCompte = (e) => {


        console.log('Organisation:', organisation); // Debug log

        if (!organisation) {
            console.log('Navigating to home'); // Debug log
            navigate('/add-status');

        } else {
            // Ouvre le modal Bootstrap pur avec jQuery ou JavaScript pur

            notify('Veillez changer de compte pour pouvoir créer un statut', 'error')
        }
    }


    if (loading) {
        return (
            <SkeletonLoaderPost />
        );
    }


    return (
        <div className="right p-2 mx-2 my-2">
            <div className='d-flex my-2'>
                <div className='px-2 txt-head'>
                    Mon Réseau
                </div>
            </div>
            <div className="status-container">
                <div className="status-card ">
                    {
                        account.photo ? <img src={FILE_URL + account.photo} alt="Story media" className="story-media12" /> : <img src={logo_site} alt="Story media" className="story-media12" />
                    }
                    {/* <button> */}
                    <div onClick={VerificatioCompte} className='nav-link card-link d-flex mt-4 justify-content-center'>
                        <button className='button-plus'>
                            <FontAwesomeIcon icon={faPlus} className='icon-plus' />
                        </button>
                        <div className='text-plus'>
                            Créer un status
                        </div>

                    </div>
                    {/* </button> */}

                </div>
                {statuts?.map((statu, index) => (
                    <div key={index} className="status-card">
                        {statu?.photo ? (
                            statu.photo === null ? (
                                <img src={logo_site} alt=".." className="profile-photo" />
                            ) : (
                                <img src={FILE_URL + statu.photo} alt={`${statu.nom} ${statu.prenom}`} className="profile-photo" />
                            )
                        ) : (
                            statu?.logo ? (
                                statu.logo === null ? (
                                    <img src={logo_site} alt=".." className="profile-photo" />
                                ) : (
                                    <img src={FILE_URL + statu.logo} alt={`${statu.nom} ${statu.prenom}`} className="profile-photo" />
                                )
                            ) : (
                                <img src={logo_site} alt=".." className="profile-photo" />
                            )
                        )}

                        <Link 
                        // to={`/status-detail/${statu.id}`}
                        onClick={() => { setIdStatus(statu.id); handleShow() }} 
                        className='nav-link card-link story-background-dark'>
                            {statu?.stories[0]?.media ? (
                                <img src={FILE_URL + statu?.stories[0]?.media} alt="Story media" className="story-media1" />
                            ) : (
                                <div className="story-description" style={{ backgroundColor: statu?.stories[0]?.color }}>
                                    {statu?.stories[0]?.description}
                                </div>
                            )}
                        </Link>

                    </div>
                ))}
            </div>
            {/* {
                statuts.length > 2 ? (
                    <div className="d-flex w-100 justify-content-center">
                        <Link to={`/status-list`} className='nav-link'>Afficher tous les statuts &rarr;</Link>

                    </div>
                ) : null
            } */}
            <ToastContainer transition={Zoom} />
            <StatusViewer id={idStatus} show={showModal} handleClose={handleClose} />
        </div>
    );
}

export default Status;
