

// import React, { useState, useEffect } from 'react';
// import { Modal, Button } from 'react-bootstrap';
// import { Scanner } from '@yudiel/react-qr-scanner';
// const QRCodePage = () => {
//   const apiUrl = 'https://api.holicorp.pro';
//   const [qrData, setQrData] = useState(null);
//   const [error, setError] = useState(null);
//   const [showResultModal, setShowResultModal] = useState(false);
//   const [showConfirmModal, setShowConfirmModal] = useState(false);
//   const [name, setName] = useState('');
//   const [surname, setSurname] = useState('');
//   const [userId, setUserId] = useState('');
//   const currentUser = JSON.parse(localStorage.getItem('account'));
//   const organisation = JSON.parse(localStorage.getItem('organisation'));
//   const handleScan = (data) => {
//     console.log('Scanned data:', data);

//     if (data && data[0] && data[0].rawValue) {
//       const rawValue = data[0].rawValue;
//       const match = rawValue.match(/^"([^/]+)\/([^/]+)\/([^/]+)"/);

//       if (match && match[1] && match[2] && match[3]) {
//         const extractedValue1 = match[1];
//         const extractedName = match[2];
//         const extractedSurname = match[3];
//         console.log(extractedValue1, extractedName, extractedSurname);
//         setUserId(extractedValue1);
//         setName(extractedName);
//         setSurname(extractedSurname);
//         setShowConfirmModal(true);


//       }
//     }
//   };

//   const handleError = (err) => {
//     console.log('QR Reader error:', err);
//     setError('Failed to read QR code.');
//     setQrData(null);
//     setShowResultModal(true);
//   };

//   const handleConfirm = () => {
//     const accessToken = localStorage.getItem('accessToken');
//     const requestBody = {
//       qrCodeId: userId,
//       type: organisation ? 'organisation' : 'compte',
//       typeId: organisation ? organisation.id : currentUser.id,
//     };
//     console.log('Request body:', requestBody);

//     fetch(`${apiUrl}/api/v1/cards/scan`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${accessToken}`
//       },
//       body: JSON.stringify(requestBody)
//     }).then(response => {
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       return response.json();
//     })
//       .then(donne => {
//         console.log('Response data:', donne);
//         if (donne.code) {
//           setQrData(donne.message);
//         } else {
//           setQrData("La carte a été enregistrée avec succès.");
//         }

//         setError(null);
//         setShowResultModal(true);
//       })
//       .catch((error) => {
//         console.log('Error:', error);
//         setError(`Une erreur est survenue lors de l'ajout de la carte.`);
//         setQrData(null);
//         setShowResultModal(true);
//       });

//     setShowConfirmModal(false);
//   };
//   const handleCloseConfirmModal = () => {
//     setShowConfirmModal(false);
//   };
//   useEffect(() => {
//     return () => {
//       // Stop all video tracks when component unmounts
//       const videos = document.querySelectorAll('video');
//       videos.forEach(video => {
//         if (video.srcObject) {
//           video.srcObject.getTracks().forEach(track => {
//             track.stop();
//             console.log('Stopped track:', track);
//           });
//         }
//       });
//     };
//   }, []);

//   return (
//     <div>
//       <div style={{ width: '100%', height: '300px', marginBottom: '10px' }}>
//         <Scanner onScan={(result, error) => {
//           console.log('QR Reader result:', result);
//           console.log('QR Reader error:', error);
//           if (!!result) {
//             handleScan(result);


//           }
//           if (!!error) {
//             handleError(error);
//           }
//         }} />
//       </div>

//       <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
//         {/* <Modal.Header closeButton>
//           <Modal.Title>Confirm QR Code Data</Modal.Title>
//         </Modal.Header> */}
//         <Modal.Body>
//           <p>Souhaitez-vous ajouter la carte de {surname} {name} à votre {organisation ? 'organisation' : 'compte'} ?</p>
//           {/* <p>Prénom: </p> */}
//         </Modal.Body>
//         <Modal.Footer className="d-flex justify-content-between">
//           <Button variant="secondary" style={{ width: '10vw' }} onClick={handleCloseConfirmModal}>
//             Nom
//           </Button>
//           <Button variant="primary" style={{ width: '10vw' }} onClick={handleConfirm}>
//             Oui
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
//         {/* <Modal.Header closeButton>
//           <Modal.Title>QR Code Result</Modal.Title>
//         </Modal.Header> */}
//         <Modal.Body>
//           {qrData ? (
//             <div>
//               <p>{qrData}</p>
//             </div>
//           ) : (
//             <div>
//               <h6>Error:</h6>
//               <p>{error}</p>
//             </div>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" style={{ width: '20vw' }} onClick={() => setShowResultModal(false)}>
//             Fermer
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default QRCodePage;
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Scanner } from '@yudiel/react-qr-scanner';

const QRCodePage = () => {
  const [qrData, setQrData] = useState(null);
  const [error, setError] = useState(null);
  const [showResultModal, setShowResultModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [userId, setUserId] = useState('');
  const [isScanning, setIsScanning] = useState(true); // Pour activer/désactiver le scanner
  const [isProcessing, setIsProcessing] = useState(false); // Pour éviter les scans multiples

  const currentUser = JSON.parse(localStorage.getItem('account'));
  const organisation = JSON.parse(localStorage.getItem('organisation'));

  const handleScan = (data) => {
    if (isProcessing || !isScanning) return; // Si déjà en train de traiter ou scanner désactivé

    if (data && data[0] && data[0].rawValue) {
      const rawValue = data[0].rawValue;
      const match = rawValue.match(/^"([^/]+)\/([^/]+)\/([^/]+)"/);

      if (match && match[1] && match[2] && match[3]) {
        setUserId(match[1]);
        setName(match[2]);
        setSurname(match[3]);

        setIsProcessing(true); // Empêcher tout autre scan
        setIsScanning(false); // Désactiver le scanner pendant le traitement

        setShowConfirmModal(true); // Afficher la confirmation
      }
    }
  };

  const handleError = (err) => {
    console.log('QR Reader error:', err);
    setError('Failed to read QR code.');
    setShowResultModal(true);
    setIsProcessing(false); // Relâcher l'état de traitement après une erreur
  };

  const handleConfirm = () => {
    const accessToken = localStorage.getItem('accessToken');
    const requestBody = {
      qrCodeId: userId,
      type: organisation ? 'organisation' : 'compte',
      typeId: organisation ? organisation.id : currentUser.id,
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/cards/scan`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.code) {
          setQrData(data.message);
        } else {
          setQrData('La carte a été enregistrée avec succès.');
        }
        setShowResultModal(true);
      })
      .catch((error) => {
        setError('Une erreur est survenue lors de l\'ajout de la carte.');
        setShowResultModal(true);
      })
      .finally(() => {
        setShowConfirmModal(false); // Fermer le modal de confirmation
        setIsScanning(true); // Réactiver le scanner après confirmation
        setIsProcessing(false); // Relâcher l'état de traitement
      });
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
    setIsScanning(true); // Réactiver le scanner après fermeture sans confirmation
    setIsProcessing(false); // Relâcher l'état de traitement
  };

  useEffect(() => {
    return () => {
      // Stop all video tracks when component unmounts
      const videos = document.querySelectorAll('video');
      videos.forEach((video) => {
        if (video.srcObject) {
          video.srcObject.getTracks().forEach((track) => {
            track.stop();
          });
        }
      });
    };
  }, []);
  
  return (
    <div>
      
      <div style={{ width: '100%', height: '300px', marginBottom: '10px' }}>
        {isScanning && (
          <Scanner
            onScan={(result, error) => {
              if (!!result) {
                handleScan(result);
              }
              if (!!error) {
                handleError(error);
              }
            }}
          />
        )}
      </div>

      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
        <Modal.Body>
          <p>Souhaitez-vous ajouter la carte de {surname} {name} à votre {organisation ? 'organisation' : 'compte'} ?</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleCloseConfirmModal}>
            Non
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Oui
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
        <Modal.Body>
          {qrData ? <p>{qrData}</p> : <p>{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowResultModal(false)}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default QRCodePage;
