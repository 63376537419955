

/* eslint-disable jsx-a11y/anchor-is-valid */
import add from '../image/icon-card/add.png'
import budget from '../image/icon-card/budget.png'
import deadline from '../image/icon-card/deadline.png'
import interested from '../image/icon-card/interested.png'

import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProjetForm from '../Projet/AddProjet'
import Left from '../Accueil/Left'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SkeletonLoaderPost from '../SkeletonLoad/skeletonloaderPost'
function LisProjet() {
    const [projets, setProjets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [idProject, setIdProject] = useState('');
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/projects`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                setProjets(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching posts:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const interProjet = (e) => {
        const accessToken = localStorage.getItem('accessToken');
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/projects/${e}/interest`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(response => response.json())
            .then(data => {
                console.log('Success partage carte:', data);
                notify("Votre carte est partagé avec succès !");
                // setLoading(false);
            })
            .catch((error) => {
                console.error('ErrorPartage:', error);
                notify("Erreur lors du partage de votre carte !", 'error');
                // setLoading(false);
            });

    }
    const handleSondageAdded = (newData) => {
        setProjets([newData, ...projets]);
    };
    // const chunkedPosts = chunkArray(projets, 2);

    if (loading) {
        return (
            <SkeletonLoaderPost />
        );
    } else if (projets.length === 0) {
        return (
            <div className="right p-2 mx-md-2 my-2 h-25  position-relative">
                <div className='d-flex my-2'>
                    <div className='px-2 txt-head'>
                        Projets
                    </div>
                    <div className='px-2 btn-add' data-bs-toggle="modal"
                        data-bs-target="#addProjetModal">
                        <img src={add} class="rounded-start flex-shrink-1" alt="..." />
                    </div>

                </div>
                <div className=' w-100 h-75 bg-body-secondary'>

                    <h6 className='position-absolute top-50 start-50 translate-middle text-center'>Aucun Projet disponible</h6>

                </div>

                <ProjetForm onProjetAdded={handleSondageAdded} />

            </div>
        )
    }
    return (
        <div className='row m-1'>

            <div className='col-12 col-sm-12 col-md-12 col-lg-8'>

                <div className="right p-2 mx-2 my-2">
                    <div className='d-flex my-2'>
                        <div className='px-2 txt-head'>
                            Projets
                        </div>
                        <div className='px-2 btn-add' data-bs-toggle="modal"
                            data-bs-target="#addProjetModal">
                            <img src={add} class="rounded-start flex-shrink-1" alt="..." />
                        </div>

                    </div>
                    <div className='container'>
                        <div className='row'>

                            {projets.map(projet => (
                                <div key={projet.id} className="g-0 my-2 mx-2 card border1 rounded flex-fill col-12 col-sm-12 col-md-12 col-lg-5 p-1" style={projets.length === 1 ? { maxWidth: '30vw' } : {  maxWidth: '30vw' }}>
                                    <h5 className='prj-title'>
                                        {projet.titre}
                                    </h5>
                                    <div className='prj-desc text-truncate' style={{ maxWidth: '250px' }}>
                                        {projet.description}
                                    </div>
                                    <div className='px-2 py-2 d-flex justify-content-start w-100'>
                                        <img src={budget} alt='...' />
                                        <div className='px-2 txt-bold  text-truncate' style={{ maxWidth: '100vw' }}>
                                            <span className='txt-bd'>Budget :</span>  Maxi: {projet.max} Min : {projet.min}
                                        </div>
                                    </div>
                                    <div className='px-2 d-flex justify-content-start w-100'>
                                        <img src={deadline} alt='...' />
                                        <div className='px-2 txt-bold'>
                                            <span className='txt-bd'>deadline : </span>  {new Date(projet.date).toLocaleDateString()}
                                        </div>
                                    </div>

                                    <div className='px-2 py-3 d-flex  align-items-center justify-content-between w-100'>
                                        <button className='prj-btn d-flex justify-content-center' onClick={() => setIdProject(projet.id)} data-bs-toggle="modal" data-bs-target="#InterestedModal">
                                            <img src={interested} alt='...' /> <span className='txt-btn px-1'>Intéresser</span>
                                        </button>
                                        <button className='prj-btn1 my-2 my-md-0'>
                                            <Link to={`/projet-detail/${projet.id}`} className='nav-link'>

                                                voir detail
                                            </Link>
                                        </button>

                                    </div>

                                </div>
                            ))}

                        </div>
                    </div>


                </div>

                <div class="modal fade" id="InterestedModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header d-flex justify-content-between p-2">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Êtes vous vraiment Intéresser ?</h1>
                                <button type="button" className='button-close-style' data-bs-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={faXmark} />
                                </button>
                            </div>
                            <div class="modal-body">
                                {/* <h3> Êtes vous vraiment Intéresser </h3> */}
                                <button type="button" className='partager-button-style' data-bs-dismiss="modal" aria-label="Close" onClick={() => interProjet(idProject)}>
                                    <FontAwesomeIcon icon={faShare} /> &nbsp;
                                    Partagez nous votre carte
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <ToastContainer transition={Zoom} />
                <ProjetForm onProjetAdded={handleSondageAdded} />

            </div>
            <div className='col-4'>
                <Left />
            </div>

        </div>
    )
}

export default LisProjet;

