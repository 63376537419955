import Articles from "../Articles/Articles";
import Connexion from "../Connexion/Connexion";

function Main(){
    return (
        <div className="main">
            <Connexion/>
            <Articles/>
            
        </div>
    )
}
export default Main;