/* eslint-disable no-unused-vars */

import React, { useState,useEffect } from 'react';
import { Bounce, Slide, Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import date_image from '../image/date-limite.png'
import DatePicker from 'react-datepicker';

function ProjetForm({ onProjetAdded, onProjetUpdated, projetToEdit, isEditMode }) {
    
    const idOrganisation = JSON.parse(localStorage.getItem("organisation"))?.id;
    const [formData, setFormData] = useState({
        titre: '',
        date: '',
        description: '',
        min: 0,
        max: 0,
        ...(idOrganisation && { organisationId: idOrganisation })
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    // const [showDateModal, setShowDateModal] = useState(false);

    useEffect(() => {
        if (projetToEdit) {
            setFormData({
                titre: projetToEdit.titre,
                date: projetToEdit.date,
                min: projetToEdit.min,
                max: projetToEdit.max,
                description: projetToEdit.description,
                ...(idOrganisation && { organisationId: idOrganisation })
            });
        }
    }, [idOrganisation, projetToEdit]);
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            date: date.toISOString().split('T')[0]
        });
        // setShowDateModal(false);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.titre) newErrors.titre = 'Le titre est requis';
        if (!formData.description) newErrors.description = 'La description est requise';
        if (!formData.date) newErrors.date = 'La date est requise';
        if (!formData.min) newErrors.min = 'Le min est requis';
        if (!formData.max) newErrors.max = 'Le max est requis';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!idOrganisation) {
            notify("Ce compte n'est pas autorisé à créer un projet");
            return;
        }
        if (!validateForm()) return;

        setLoading(true);
        console.log(JSON.stringify({
            titre: formData.titre,
            description: formData.description,
            date: formData.date,
            min: Number(formData.min),
            max: Number(formData.max),
            organisationId: idOrganisation

        }));

        const accessToken = localStorage.getItem('accessToken');
        const url = isEditMode ? `${process.env.REACT_APP_API_URL}/api/v1/projects/${projetToEdit.id}` : `${process.env.REACT_APP_API_URL}/api/v1/projects`;
        const method = isEditMode ? 'PUT' : 'POST';
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                titre: formData.titre,
                description: formData.description,
                date: formData.date,
                min: Number(formData.min),
                max: Number(formData.max),
                organisationId: idOrganisation

            })
        }).then(response => response.json())
            .then(data => {
                console.log('SuccessProjet:', data);
                
                notify(isEditMode ? "Projet modifié avec succès !" : "Projet ajouté avec succès !");
                setLoading(false);
                if (isEditMode) {
                    onProjetUpdated(data);
                } else {
                    onProjetAdded(data);
                }
                // Actualiser la page
                // window.location.reload();
            })
            .catch((error) => {
                console.error('ErrorProjet:', error);
                notify("Erreur lors de l'ajout du Projet");
                setLoading(false);
            });
    };
    return (
        <>

            <div class="modal fade" id="addProjetModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-lg modal-dialog modal-dialog-centered  modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            {
                                isEditMode ? <h1 className="modal-title fs-4" id="exampleModalLabel">Modifier Projet</h1> : <h1 className="modal-title fs-4" id="exampleModalLabel">Créer Projet</h1>
                            }
                            {/* <h1 class="modal-title fs-4" id="exampleModalLabel">Créer Projet</h1> */}
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <form onSubmit={handleSubmit} >

                                <div className='mb-3'>

                                    <label htmlFor="titre" class="form-label">Titre</label>
                                    <input type="text" className={`form-control ${errors.titre ? 'is-invalid' : ''}`} id="titre" name="titre" value={formData.titre} onChange={handleChange} placeholder="Veuillez saisir le titre" />
                                    {errors.titre && <div className="invalid-feedback">{errors.titre}</div>}

                                </div>

                                <div className='mb-3'>

                                    <label htmlFor="date" class="form-label">Deadline</label>
                                    <div className="input-group">
                                        <input type="text" className={`form-control ${errors.date ? 'is-invalid' : ''}`} id="date" name="date" value={formData.date} onChange={handleChange} placeholder="Veuillez saisir la date" data-bs-target="#exampleModalToggle3" data-bs-toggle="modal" />
                                        <span className="input-group-text" data-bs-target="#exampleModalToggle3" data-bs-toggle="modal">
                                            <img src={date_image} width="30" alt='..' />
                                        </span>
                                        {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                                    </div>

                                </div>





                                <div className='mb-3 row'>
                                    <label for="Budget" class="form-label">Budget</label>
                                    <div className='col-6 d-flex'>
                                        <label for="min" class="form-label">Min</label>
                                        {/* <input type='number' min="50000" name='minMontant' class="form-control input-montant" placeholder="Saisir le montant max" id='minMontant' /> */}
                                        <input type="number" className={`form-control ${errors.min ? 'is-invalid' : ''}`} id="min" name="min" value={formData.min} onChange={handleChange} placeholder="Saisir le montant min" />
                                        {errors.min && <div className="invalid-feedback">{errors.min}</div>}
                                    </div>
                                    <div className='col-6 d-flex'>
                                        <label for="max" class="form-label">Max</label>
                                        {/* <input type='number' min="50000" name='maxMontant' class="form-control input-montant" placeholder="Saisir le montant max" id='maxMontant' /> */}
                                        <input type="number" className={`form-control ${errors.max ? 'is-invalid' : ''}`} id="max" name="max" value={formData.max} onChange={handleChange} placeholder="Saisir le montant max" />
                                        {errors.max && <div className="invalid-feedback">{errors.max}</div>}

                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <textarea id="description" className={`form-control ${errors.description ? 'is-invalid' : ''}`} name="description" value={formData.description} onChange={handleChange} required placeholder="Veuillez saisir la description de votre evenement"></textarea>
                                    {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                                </div>






                                <div className="w-100 d-flex justify-content-end">
                                    <button className='btn-ad' type="submit" disabled={loading}>
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : isEditMode ? 'Modifier' : 'Ajouter'}
                                    </button>

                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>


            <div class="modal fade" id="exampleModalToggle3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalToggle3" aria-hidden="true">
                <div class="modal-lg modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalToggleLabel3">Deadline du projet</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" >
                            <div className='date-piker-style' data-bs-target="#addProjetModal" data-bs-toggle="modal">
                                <DatePicker

                                    selected={formData.date ? new Date(formData.date) : null}
                                    onChange={handleDateChange}
                                    inline
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <ToastContainer transition={Zoom} />

        </>
    );
}

export default ProjetForm;