
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import './Connexion.css';
import login from '../image/login.png';
import qrcode from '../image/qr-code.png';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppStoreIos, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
function Connexion() {
  const [countries, setCountries] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentStep, setCurrentStep] = useState('phone'); // 'phone', 'otp', or 'register'
  const [otp, setOtp] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [profession, setProfession] = useState('');
  const [address, setAddress] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const navigate = useNavigate();
  const playstoreLink = "https://play.google.com/store/apps/details?id=com.sosmobile.holilink&hl=fr";
  const notify = (message, type = 'success') => {
    toast[type](message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const appStoreMessage = (e) => {
    e.preventDefault(); // Empêche la navigation par défaut
    notify('Lien AppStore non disponible pour le moment', 'error');
  }
  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };


  const handleProfessionChange = (e) => {
    setProfession(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/countries`);
        setCountries(response.data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentStep === 'phone' && selectedCountry && phoneNumber) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/check`, {
          phone: phoneNumber,
          country: selectedCountry.id
        });
        console.log('Response:', response.data);
        setConfirmationCode(response.data.code);
        setCurrentStep('otp');
      } catch (error) {
        console.error('Error submitting data:', error);
      }
    } else if (currentStep === 'otp' && otp) {
      // Validate OTP here
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/signin`, {
          phone: phoneNumber,
          country: selectedCountry.id,
          confirmationCode: confirmationCode,
          uid: "xx4xxx",
          fcm: "zzzwwwxxx",
          device: "Samsung"
        });
        console.log('OTP Response:', response.data);

        if (response.data.accessToken && response.data.expireIn) {
          // Store the required values in localStorage
          const { accessToken, id, account } = response.data;
          console.log(accessToken);
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('userId', id);
          localStorage.setItem('selectedItem', account.id);
          localStorage.setItem('account', JSON.stringify(account));

          // Redirect to homepage
          navigate('/');
        } else {
          setCurrentStep('register');
        }

      } catch (error) {
        console.error('Error verifying OTP:', error);
      }
    } else if (currentStep === 'register') {
      // Handle registration here
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/signup`, {
          phone: phoneNumber,
          country: selectedCountry.id,
          prenom: firstName,
          email: email,
          nom: lastName,
          profession: profession,
          confirmationCode: confirmationCode,
          adresse: address,
          uid: "xx4xxx",
          fcm: "zzzwwwxxx",
          device: "Samsung"
        });
        console.log('Registration Response:', response.data);

        // Store the required values in localStorage
        const { accessToken, id, account } = response.data;
        console.log(accessToken);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('userId', id);
        localStorage.setItem('selectedItem', account.id);
        localStorage.setItem('account', JSON.stringify(account));

        // Redirect to homepage
        navigate('/');
      } catch (error) {
        console.log(confirmationCode);
        console.error('Error registering user:', error);
      }
    }
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <section className='d-flex flex-column flex-md-row form'>

      <div className='p-5 w-100 flex-shrink-1 pt-4'>
        <div className='d-flex justify-content-between'>
          <button type='button' className='playstore'>
            <Link className='nav-link d-flex justify-content-center align-items-center' to={playstoreLink} onClick={(e) => {
              e.preventDefault(); // Empêche la navigation par défaut
              openInNewTab(playstoreLink);
            }}>
              <FontAwesomeIcon icon={faGooglePlay} className='icon-store' />
              PlayStore</Link>
          </button>
          <button type='button' className='playstore ' onClick={appStoreMessage}>

            <Link className='nav-link d-flex justify-content-center align-items-center' to={''} >
              <FontAwesomeIcon icon={faAppStoreIos} className='icon-store' />
              AppStore</Link>

          </button>
        </div>
        <img src={login} className='p-2 w-100 img-fluid' alt='Login' />
      </div>
      <div className="login-form  p-3">
        <h1 className="text-center">Bienvenu dans votre communauté d’Innovation, d’Excellence, Professionnelle</h1>
        <div className='type-inscript text-center'>Connecter à HoliLink à l'aide d'un code QR</div>
        <div className='scan d-flex justify-content-center'>
          <img src={qrcode} className="img-fluid" alt="QR code" />
        </div>
        <div className="separator text-center my-3">
          <span>ou</span>
        </div>
        <div className='type-inscript mb-3 text-center'>En utilisant un numéro de téléphone</div>
        <div className="container">
          <div className="row justify-content-md-center">
            <form onSubmit={handleSubmit}>
              {currentStep === 'phone' && (
                <div className="form-group">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      id="countryCode"
                      className="form-control select-container"
                      placeholder="indicatif"
                      value={selectedCountry ? selectedCountry.code : ''}
                      onClick={() => setShowModal(true)}
                      readOnly
                    />
                    <input
                      type="tel"
                      required
                      id="phoneNumber"
                      className="form-control select-container1"
                      placeholder="70 70 70 70"
                      pattern="[0-9]*"
                      value={phoneNumber}
                      onChange={handlePhoneChange}
                    />
                  </div>
                  <div className="d-grid gap-2 col-6 mx-auto">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!phoneNumber || !selectedCountry}
                    >
                      Suivant
                    </button>
                  </div>
                </div>
              )}
              {currentStep === 'otp' && (
                <div className="form-group mb-3">
                  <div className='type-inscript mb-3 text-center'>
                    Nous venons de vous envoyer un code de vérification par sms.
                    Veuillez le saisir ci-dessous. {confirmationCode}
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      id="otp"
                      className="form-control"
                      placeholder="Saisir le code OTP"
                      value={otp}
                      onChange={handleOtpChange}
                    />
                  </div>
                  <div className="d-grid gap-2 col-6 mx-auto">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!otp}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              )}
              {currentStep === 'register' && (
                <div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      id="firstName"
                      className="form-control"
                      placeholder="Prénom"
                      value={firstName}
                      onChange={handleFirstNameChange}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      id="lastName"
                      className="form-control"
                      placeholder="Nom"
                      value={lastName}
                      onChange={handleLastNameChange}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      id="countryCode"
                      className="form-control select-container"
                      placeholder="Selectionnez le pays"
                      value={selectedCountry ? selectedCountry.code : ''}
                      readOnly
                    />
                    <input
                      type="text"
                      id="phoneNumber"
                      className="form-control select-container1"
                      placeholder="Ex: 77 77 77 01"
                      value={phoneNumber}
                      readOnly
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      id="profession"
                      className="form-control"
                      placeholder="Profession"
                      value={profession}
                      onChange={handleProfessionChange}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      id="address"
                      className="form-control"
                      placeholder="Adresse"
                      value={address}
                      onChange={handleAddressChange}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                  >
                    Enregistrer
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Sélectionnez un pays</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-group">
            {countries.map((country) => (
              <li
                key={country.id}
                className="list-group-item"
                onClick={() => handleCountrySelect(country)}
                style={{ cursor: 'pointer' }}
              >
                {country.nom} ({country.code})
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
      <ToastContainer transition={Zoom} />
    </section>
  );
}

export default Connexion;