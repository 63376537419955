import React, { useState, useEffect } from 'react';

function App() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);

    useEffect(() => {
        // Écouter l'événement 'beforeinstallprompt'
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            // Sauvegarder l'événement pour pouvoir l'utiliser plus tard
            setDeferredPrompt(e);
            // Afficher immédiatement le popup dès que l'application est chargée
            setShowInstallPrompt(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        // Nettoyer l'événement lors du démontage du composant
        return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            // Afficher l'invite d'installation
            deferredPrompt.prompt();
            // Attendre la réponse de l'utilisateur
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('L\'utilisateur a accepté d\'installer l\'app');
                } else {
                    console.log('L\'utilisateur a refusé d\'installer l\'app');
                }
                // Réinitialiser le prompt
                setDeferredPrompt(null);
                setShowInstallPrompt(false);
            });
        }
    };

    return (
        <div className="App">

            {/* Afficher le popup lorsque l'invite est prête */}
            {showInstallPrompt && (
                <div className="popup">
                    <h2>Bienvenue sur Holilink</h2>
                    <p className='text-center'>Pour une expérience optimale, installez Holilink sur votre appareil ! Restez connecté et informé où que vous soyez.</p>
                    <button onClick={handleInstallClick}>Installer</button>
                </div>
            )}
        </div>
    );
}

export default App;
