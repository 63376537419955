/* eslint-disable jsx-a11y/anchor-is-valid */
import add from '../image/icon-card/add.png'
import date from '../image/icon-card/date.png'
import location from '../image/icon-card/location.png'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EvenementForm from '../Evenement/AddEvenement';
import { Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function EvenementAccueil() {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const [organisation, setOrganisation] = useState(JSON.parse(localStorage.getItem('organisation')) || '');
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    useEffect(() => {
        // Définir un intervalle qui vérifie la valeur dans le localStorage toutes les secondes
        const intervalId = setInterval(() => {
          const currentOrganisation = localStorage.getItem('organisation') || '';
          if (currentOrganisation !== organisation) {
            setOrganisation(currentOrganisation);
          }
        }, 1000); // Intervalle de 1 seconde
    
        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
      }, [organisation]); // Dépendance à l'état "organisation"

    
      const VerificatioCompte = (e) =>{

        if(!organisation){
            notify('Veillez changer de compte pour pouvoir créer un évenement','info')
        }else{
            // Ouvre le modal Bootstrap pur avec jQuery ou JavaScript pur
            const scanModal = document.getElementById('addEvenModal');
            const bootstrapModal = new window.bootstrap.Modal(scanModal);
            bootstrapModal.show();
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/events`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                // Trier les posts par date de création (du plus récent au plus ancien)
                const sortedEvent = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                // Sélectionner les six derniers posts
                const latestEvent = sortedEvent.slice(0, 6);
                setEvents(latestEvent);
                console.log(response.data.length)
                setLoading(false);
            } catch (error) {
                console.error('Error fetching posts:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    const handleEventAdded = (newData) => {
        setEvents([newData, ...events]);
    };
    const chunkedPosts = chunkArray(events, 2);
    if (loading) {
        return (

            <div className="right p-2 mx-2 my-2 h-25 position-relative">
                <div className='position-absolute top-50 start-50 translate-middle'>
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>

                </div>

            </div>
        );
    } else if (events.length === 0) {
        return (

            <div className="right p-2 mx-2 my-2 h-25 position-relative">
                <div className='d-flex my-2'>
                    <div className='px-2 txt-head'>
                        Evénements
                    </div>

                    <div className='px-2 btn-add' onClick={VerificatioCompte}>
                        <img src={add} class="rounded-start flex-shrink-1" alt="..." />
                    </div>

                </div>
                <div className=' w-100 h-75 bg-body-secondary'>

                    <h6 className='position-absolute top-50 start-50 translate-middle text-center'>Aucun Evenement disponible</h6>

                </div>
                <EvenementForm onEventAdded={handleEventAdded} />

            </div>

        )
    }
    return (
        <>
            <div className="right p-2 mx-md-2 my-2">
                <div className='d-flex my-2'>
                    <div className='px-2 txt-head'>
                        Evénements
                    </div>

                    <div className='px-2 btn-add' onClick={VerificatioCompte}>
                        <img src={add} class="rounded-start flex-shrink-1" alt="..." />
                    </div>

                </div>
                <div id="carouselEvenement" className="carousel carousel-dark slide" data-bs-ride="true">
                    <div className="carousel-inner">
                        
                        {chunkedPosts.map((chunk, chunkIndex) => (
                            <div key={chunkIndex} className={`carousel-item ${chunkIndex === 0 ? 'active' : ''}`}>
                                <div className="card-group grip gap-2 section-container">
                                    {chunk.map(event => (
                                        <div key={event.id} className="g-0 border1 card rounded" style={chunkedPosts.length === 1 || chunk.length === 1 ? { maxWidth: '30vw' } : {minWidth: '20em', maxWidth: '32vw' }}>

                                            <Link to={`/event-detail/${event.id}`} className='nav-link' style={{width: 'inherit'}}>

                                                <div className='d-flex'>
                                                    <div className='d-flex align-items-center px-md-3'>
                                                        <img src={FILE_URL + event.couverture} className="flex-shrink-0 imageEvent" alt={event.titre} />
                                                    </div>



                                                    <div className='d-flex align-content-between flex-wrap'>
                                                        <div className='px-2 d-flex justify-content-end w-100'>
                                                            <img src={date} alt='...' />
                                                            <div className='px-2'>
                                                                {new Date(event.date).toLocaleDateString()}
                                                                
                                                            </div>
                                                        </div>
                                                        <div className='content px-2'>
                                                            <h5 className='prj-title text-truncate' style={{ maxWidth: '20vw' }}>{event.titre}</h5>
                                                            <div className='px-2 description'>
                                                                {event.description}

                                                            </div>
                                                            <div className='px-2 d-flex justify-content-start w-100'>
                                                                <img src={location} alt='...' />
                                                                <h6 className='px-2'>
                                                                    {event.lieu}
                                                                </h6>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            {/* <div className='px-2 pb-1 d-flex justify-content-end w-100 bottom-button'>

                                                <button className='btn btn-ternaire'>

                                                </button>
                                            </div> */}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="carousel-indicators">
                        {chunkedPosts.map((_, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselEvenement"
                                data-bs-slide-to={index}
                                className={index === 0 ? 'active' : ''}
                                aria-current={index === 0 ? 'true' : 'false'}
                                aria-label={`Slide ${index + 1}`}
                            ></button>
                        ))}
                    </div>
                </div>


                <div class="d-flex w-100 justify-content-center">
                    {
                        events.length > 1 ? (
                            <div className="d-flex w-100 justify-content-center">
                                <Link to={`/event-list`} className='nav-link'>Afficher tous les événements &rarr;</Link>

                            </div>
                        ) : null
                    }

                </div>

            </div>
            <ToastContainer transition={Zoom} />
            <EvenementForm onEventAdded={handleEventAdded} />
        </>

    )
}

export default EvenementAccueil;

// Utility function to chunk the array
const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
};