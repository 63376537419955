/* eslint-disable jsx-a11y/alt-text */
// NotFound.js
import { Link } from 'react-router-dom';
import logo_site from './image/logo-site/image.jpg'
import NotFounds from './image/logo-site/NotFound.svg'
import React from 'react';

const NotFound = () => {
    return (
        <div class="bg-purple">

            <div class="stars">
                <div class="custom-navbar">
                    <div class="brand-logo">
                        <img src={logo_site} width="80px" />
                    </div>
                    <div class="navbar-links">
                        <ul>
                            <li className='lis'><Link to="/" >Accueil</Link></li>
                        </ul>
                    </div>
                </div>
                <div class="central-body">
                    {/* <img class="image-404" src={NotFounds} width="300px" /> */}
                    <h1 class="title-404">404</h1>
                    <h2 class="title-404">Page non trouvée</h2>
                    <p class="description-404">
                        La page que vous recherchez n'existe pas.
                    </p>
                    {/* <a href="http://salehriaz.com/404Page/404.html" class="btn-go-home" target="_blank" rel="noreferrer">GO BACK HOME</a> */}
                </div>
                <div class="objects">
                    <img class="object_rocket" src="http://salehriaz.com/404Page/img/rocket.svg" width="40px" />
                    <div class="earth-moon">
                        <img class="object_earth" src="http://salehriaz.com/404Page/img/earth.svg" width="100px" />
                        <img class="object_moon" src="http://salehriaz.com/404Page/img/moon.svg" width="80px" />
                    </div>
                    <div class="box_astronaut">
                        <img class="object_astronaut" src="http://salehriaz.com/404Page/img/astronaut.svg" width="140px" />
                    </div>
                </div>
                <div class="glowing_stars">
                    <div class="star"></div>
                    <div class="star"></div>
                    <div class="star"></div>
                    <div class="star"></div>
                    <div class="star"></div>

                </div>

            </div>

        </div>
    );
};

export default NotFound;