import React from 'react';
import qrcode from '../image/icon/qrcode.png';
import scanner from '../image/icon/scanner.png';
import partage from '../image/icon/partage.png';
import QRCodePage from './qrcode';
import { QRCodeCanvas } from 'qrcode.react'; // Use QRCodeCanvas or QRCodeSVG
import { Link, useNavigate } from 'react-router-dom';
import { RWebShare } from "react-web-share";
const Opportunite = () => {
    const currentUser = JSON.parse(localStorage.getItem('account'))
    const navigate = useNavigate();
    // Créer la chaîne à partir des données JSON
    const qrData = JSON.stringify(
        currentUser?.id + "/" +
        currentUser?.nom + "/" +
        currentUser?.prenom
    );

    const goToHomePage = () => {
        navigate('/');
    }

    return (
        <div className="modal fade" id="opportunite" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered opportunite-modal-style">
                <div className="modal-content opportunite-modal-style">
                    <div className="modal-header">
                        <h1 className="modal-title fs-4" id="exampleModalLabel">Opportunité</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={goToHomePage}></button>
                    </div>
                    <div className="modal-body" style={{ position: 'relative' }}>
                        {/* <QRCodePage /> */}

                        <div class="tab-content w-100 mb-5 d-flex justify-content-center" id="v-pills-tabContent">
                            <div class="tab-pane  fade show active " id="v-pills-qrcode" role="tabpanel" aria-labelledby="v-pills-qrcode-tab" tabindex="0">
                                <QRCodeCanvas value={qrData} size={300} level={"H"} />
                            </div>

                            <div class="tab-pane  fade show" id="v-pills-scanner" role="tabpanel" aria-labelledby="v-pills-scanner-tab" tabindex="0">
                                <QRCodePage />
                            </div>

                            <div class="tab-pane  fade show" id="v-pills-partage" role="tabpanel" aria-labelledby="v-pills-partage-tab" tabindex="0">
                                <QRCodeCanvas value={qrData} size={300} level={"H"} />
                            </div>
                        </div>

                        <div style={{ position: 'absolute', bottom: '10px', left: '0' }} class="w-100   p-1 d-flex  justify-content-center" id="pills-tab" role="tablist">

                            <button class="active butn-opportunite d-flex flex-column align-items-center" id="v-pills-qrcode-tab" data-bs-toggle="pill" data-bs-target="#v-pills-qrcode" type="button" role="tab" aria-controls="v-pills-qrcode" aria-selected="true">
                                <img src={qrcode} alt='qrcode' className='opportunite-icon' />
                                <span>Qrcode</span>
                            </button>
                            <button class="butn-opportunite d-flex flex-column align-items-center" id="v-pills-scanner-tab" data-bs-toggle="pill" data-bs-target="#v-pills-scanner" type="button" role="tab" aria-controls="v-pills-scanner" aria-selected="false">
                                <img src={scanner} alt='qrcode' className='opportunite-icon' />
                                <span>Scanner</span>
                            </button>
                            <button class="butn-opportunite d-flex flex-column align-items-center" id="v-pills-partage-tab" data-bs-toggle="pill" data-bs-target="#v-pills-partage" type="button" role="tab" aria-controls="v-pills-partage" aria-selected="false" >
                                <div class="btn-group dropup">
                                    <button type="button" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={partage} alt='qrcode' className='opportunite-icon' />
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <RWebShare
                                                data={{
                                                    text: "Like humans, flamingos make friends for life",
                                                    url: "https://on.natgeo.com/2zHaNup",
                                                    title: "Flamingos",
                                                }}
                                                onClick={() => console.log("shared successfully!")}
                                            >
                                                <Link class="dropdown-item nav-link">Share 🔗</Link>
                                            </RWebShare>
                                            Partager
                                        </li>
                                        <li><Link class="dropdown-item nav-link" href="#">Telecharger</Link></li>
                                    </ul>
                                </div>

                                <span>Partage</span>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Opportunite;