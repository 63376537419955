/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import participed from '../image/icon-card/participe.png'
import '../Post/PostDetail.css'
import Left from "../Accueil/Left";
import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import date from '../image/icon-card/date.png'
import location from '../image/icon-card/location.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faEnvelope, faEye, faGlobe, faLocationDot, faPen, faPhoneAlt, faShare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import EvenementForm from './AddEvenement';
import { toast } from 'react-toastify';
import logo_site from '../image/logo-site/image.jpg'
function EvenementDetail() {
    const idOrganisation = JSON.parse(localStorage.getItem("organisation"))?.id;
    const { id } = useParams();
    const [event, setEvent] = useState([]);
    const [stateDetail, setStateDetail] = useState(true);
    const [stateParticipant, setStateParticipant] = useState(false);
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const [isEditMode, setIsEditMode] = useState(false);
    const [isInterested, setIsInterested] = useState(false);
    const [idProject, setIdProject] = useState('');
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const navigate = useNavigate();
    const fetchData = async () => {
        const accessToken = localStorage.getItem('accessToken');
        console.log("voici l'id", id);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/events/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log(response.data)
            setEvent(response.data);
            response.data.participants.map(
                (interes) => {
                    if (interes.compte.id === JSON.parse(localStorage.getItem("account"))?.id) {
                        setIsInterested(true);
                    }else{
                        setIsInterested(false);
                    }
                }
            )
            setLoading(false);
        } catch (error) {
            console.error('Error fetching detail:', error);
            setLoading(false);
        }
    };



    const handleDeleteEvent = async () => {
        // e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/events/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            fetchData(); // Refresh comments after deletion
            navigate('/');
            console.log('text');

        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    const handleEventUpdated = (updatedEvent) => {
        setEvent(updatedEvent);
    };

    const interProjet = (e) => {
        const accessToken = localStorage.getItem('accessToken');
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/events/${e}/interest`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(response => response.json())
            .then(data => {
                console.log('Success partage carte:', data);
                fetchData();
                notify("Operation reussite !");
                // setLoading(false);
            })
            .catch((error) => {
                console.error('ErrorPartage:', error);
                notify("Erreur lors du partage de votre carte !", 'error');
                // setLoading(false);
            });

    }

    useEffect(() => {
        fetchData();
    }, []);
    // const chunkedPosts = chunkArray(posts, 2);
    if (loading) {
        return (
            <div className="right p-2 mx-2 my-2 h-25 position-relative">
                <div className='position-absolute top-50 start-50 translate-middle'>
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>

                </div>

            </div>
        );
    }
    return (
        <div className="row m-2">

            <div className="col-12 col-sm-12 col-md-12 col-lg-8">

                <div className="right  mx-2 my-2" key={event.id}>
                    <div className='d-flex justify-content-between p-2'>
                        <div className='d-flex pb-2'>
                            <div className=''>
                                <img src={FILE_URL + event.organisation?.logo} className='image-comment rounded-circle' alt='logo organisation' />
                            </div>

                            <div className=''>
                                <div className='non-publica-style'>
                                    {event.organisation?.nom}
                                </div>
                                <div className='date-publica-style'>
                                    {new Date(event.createdAt).toLocaleDateString()}
                                </div>
                            </div>
                        </div>

                        {
                            idOrganisation === event.organisation.id && (<div className='dropdown'>
                                <button className="btn-non-border-style" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FontAwesomeIcon icon={faEllipsisVertical} className='font-icon' />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    {/* <li><Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#addpostModal" onClick={() => setIsEditMode(true)}> Modifier </Link></li> */}

                                    <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#addEvenModal" onClick={() => setIsEditMode(true)}><FontAwesomeIcon icon={faPenToSquare} className='font-icon px-2' /> Modifier</button></li>
                                    <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#supeventModal"><FontAwesomeIcon icon={faTrashCan} className='font-icon px-2' /> Supprimer</button></li>

                                </ul>

                                <div className="modal fade" id="supeventModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className=" modal-dialog modal-dialog-centered ">
                                        <div className="modal-content">
                                            <div className="modal-header">

                                                <h5 className='modal-title fs-4'>Supprimer l'Événement</h5>

                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">

                                                <div className='text-center'>

                                                    <p>Vous êtes sur le point de supprimer cet Événement. Voulez-vous continuer ?</p>


                                                </div>

                                                <div className='d-flex justify-content-between align-item-center'>
                                                    <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">Non</button>
                                                    <button className="btn btn-success px-3" type="button" onClick={() => handleDeleteEvent()} data-bs-dismiss="modal"> Oui</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }

                    </div>
                    <div className="couvercle w-100">
                        <img src={FILE_URL + event.couverture} className='image-detail-post' alt="..." />
                    </div>

                    <div className='text-justify-style px-2'>
                        <div className='icon-content-color d-flex justify-content-end'>
                            {isInterested ? <button className='prj-btn2 d-flex justify-content-center' onClick={() => { interProjet(event.id) }} >
                                <img src={participed} width="25px" alt='...' /> <span className='txt-btn px-1'>
                                    Ne plus participer
                                </span>
                            </button> : <button className='prj-btn1 d-flex justify-content-center' onClick={() => { interProjet(event.id) }} >
                                <img src={participed} width="25px" alt='...' /> <span className='txt-btn px-1'>
                                    Participer
                                </span>
                            </button>}
                        </div>
                        <h5 className="pt-2">
                            {event.titre}
                        </h5>
                        {/* <div className="text-justify">
                            {event.description}

                        </div> */}
                        <div>
                            Événement de {event.organisation?.nom}
                        </div>
                    </div>


                    <div className='d-flex flex-column justify-content-between py-1  my-2 mx-4'>
                        <div className=' d-flex pt-2'>
                            <img src={date} width="30px" alt='...' />
                            <div className='px-1'>
                                {new Date(event.date).toLocaleDateString()}

                            </div>
                        </div>
                        <div className=' d-flex pt-2'>
                            <img src={location} width="30px" alt='...' />
                            <div className='px-1'>
                                {event.lieu}

                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between py-1  my-2 mx-1 menu-post-style'>

                        <button type='button' className='tab' onClick={() => { setStateDetail(true); setStateParticipant(false) }}>
                            Détails
                        </button>


                        <button type='button' className='tab' onClick={() => { setStateParticipant(true); setStateDetail(false) }}>
                            Participants
                        </button>



                    </div>

                </div>
                {
                    (stateDetail) && <div className="right  mx-2 my-2" key={event.id}>
                        <div className="text-justify-style p-3">
                            {event.description}

                        </div>
                    </div>
                }
                {
                    (stateParticipant) && <>
                        <div className="right p-2 mx-2 my-2 pb-0">
                            <h4 className='my-3'>
                                Liste des intéressé(e)s
                            </h4>
                            <div class="container">
                                <div class="row">
                                    {

                                        Array.isArray(event.participants) && event.participants.lenght !== 0 ? event.participants.map(cards => (
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4" key={cards.compte.id}>
                                                <div class="our-team">
                                                    <div className='d-flex justify-content-end'>
                                                        <div class="dropdown dropdown1">
                                                            <button class="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                {/* <img src={seting} width="25px" alt='..' /> */}
                                                                <FontAwesomeIcon icon={faEllipsisVertical} className='icon' />
                                                            </button>
                                                            <ul class="dropdown-menu dropdown-menu-white">
                                                                <Link class="nav-link" to={`/cartes-detail/${cards.compte.id}`}>
                                                                    <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >
                                                                        {/* <img src={detail} className='icon-seting' alt='...' /> */}
                                                                        <FontAwesomeIcon icon={faEye} className='icon-seting' />
                                                                        Detail
                                                                    </li>
                                                                </Link>
                                                                <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' onClick={() => {
                                                                    //handleType('compte');
                                                                    //handleTypeId(count.id);
                                                                    setIsEditMode(true);
                                                                    //handleSetCart(cards.id)

                                                                }} data-bs-toggle="modal" data-bs-target="#addCarteModal"  >

                                                                    <FontAwesomeIcon icon={faPen} className='icon-seting' />
                                                                    <Link class="nav-link" to="#">Modifier</Link>
                                                                </li>

                                                                <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' onClick={() => {
                                                                    //handleType('compte');
                                                                    //handleTypeId(count.id)

                                                                }} data-bs-toggle="modal" data-bs-target="#supCarteModal" >

                                                                    <FontAwesomeIcon icon={faTrashCan} className='icon-seting' />
                                                                    <Link class="nav-link" to="#">Supprimer</Link>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="modal fade" id="supCarteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className=" modal-dialog modal-dialog-centered ">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">

                                                                        <h5 className='modal-title fs-4'>Supprimer la Carte</h5>

                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">

                                                                        <div className='text-center'>

                                                                            <p>Vous êtes sur le point de supprimer une carte. Voulez-vous continuer?</p>


                                                                        </div>

                                                                        <div className='d-flex justify-content-between align-item-center'>
                                                                            <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">Non</button>
                                                                            <button className="btn btn-success px-3" type="button" data-bs-dismiss="modal"> Oui</button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="picture">
                                                        {
                                                            cards.photo ? <img src={FILE_URL + cards.photo} className='img-fluid' alt='..' /> : <img src={logo_site} className='img-fluid' alt='..' />
                                                        }
                                                        {/* <img class="img-fluid" src="https://picsum.photos/130/130?image=1027" /> */}
                                                    </div>
                                                    <div class="team-content">
                                                        <h5 class="name">{cards.compte.prenom} {cards.compte.nom}</h5>
                                                        <h4 class="title text-truncate" >{cards.compte.profession}</h4>

                                                    </div>

                                                    <div class="social">

                                                        <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.compte.adresse}`}>
                                                            <FontAwesomeIcon icon={faLocationDot} className='icon' />
                                                        </button>
                                                        <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.compte.site}`}>
                                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                                        </button>
                                                        <button type="button" class="" data-bs-container="body" key={cards.compte.emails[0].id} data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.compte?.emails[0]?.email}`}>
                                                            <FontAwesomeIcon icon={faEnvelope} className='icon' />
                                                        </button>
                                                        <button type="button" class="" data-bs-container="body" key={cards.compte?.phones[0].id} data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.compte?.phones[0]?.phone}`}>
                                                            <FontAwesomeIcon icon={faPhoneAlt} className='icon' />
                                                        </button>


                                                    </div>
                                                </div>
                                            </div>
                                        )) : <div className='text-center'>Aucun intéressé(e) pour le moment</div>
                                    }


                                </div>
                            </div>
                        </div>



                    </>
                }

                <EvenementForm eventToEdit={event} isEditMode={isEditMode} onEventAdded={() => {/* Rafraîchir la liste des posts */ }} onEventUpdated={handleEventUpdated} />
            </div>
            <div className="col-4">
                <Left />

            </div>
        </div>
    )
}

export default EvenementDetail;