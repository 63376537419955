/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { Bounce, Slide, Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import date_image from '../image/date-limite.png'
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

function CarteForm({ onCarteAdded, onCarteUpdated, carteToEdit, isEditMode, type, typeId }) {

    const idOrganisation = JSON.parse(localStorage.getItem("organisation"));

    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [pays, setPays] = useState(null);
    const [formData, setFormData] = useState({
        prenom: '',
        nom: '',
        telephone: '',
        email: '',
        site: '',
        profession: '',
        adresse: '',
        type: type,
        typeId: typeId,
        country: selectedCountry?.id
    });
    const handlePhoneChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleCountrySelect = (country) => {
        setSelectedCountry(country);
        setShowModal(false);
    };
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/countries`);
                setCountries(response.data);
                if (isEditMode) {
                    setSelectedCountry(response.data.find(country => country.id === carteToEdit?.phones[0].country.id));
                }
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, [carteToEdit?.phones, isEditMode]);

    useEffect(() => {
        if (carteToEdit) {
            setFormData({
                prenom: carteToEdit.prenom,
                nom: carteToEdit.nom,
                adresse: carteToEdit.adresse,
                telephone: carteToEdit?.phones[0].phone,
                email: carteToEdit?.emails[0].email,
                site: carteToEdit.site,
                profession: carteToEdit.profession,
                type: type,
                typeId: typeId,
                country: carteToEdit.country
            });
            console.log(carteToEdit)
        }
    }, [typeId, carteToEdit, type]);
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            date: date.toISOString().split('T')[0]
        });
        // setShowDateModal(false);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.prenom) newErrors.prenom = 'Le prenom est requis';
        if (!formData.nom) newErrors.nom = 'Le nom est requise';
        if (!formData.telephone) newErrors.telephone = 'Le Numéro du téléphone est requise';
        if (!formData.email) newErrors.email = 'L\'email est requis';
        if (!formData.site) newErrors.site = 'Le site est requis';
        if (!formData.profession) newErrors.profession = 'Le profession est requis';
        if (!formData.adresse) newErrors.site = 'L\'adresse est requis';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('bonjour')
        console.log(JSON.stringify({
            prenom: formData.prenom,
            nom: formData.nom,
            telephone: formData.telephone,
            email: formData.email,
            profession: formData.profession,
            site: formData.site,
            adresse: formData.adresse,
            type: type,
            typeId: typeId,
            country: selectedCountry?.id

        }));

        if (type === 'organisation' && typeId === "" && !isEditMode) {
            notify("Ce compte n'est pas autorisé à créer une carte");
            return;
        }
        console.log('bonjour')
        if (!validateForm() && !isEditMode) return console.log('erre');
        console.log('bonjour1')

        setLoading(true);
        const accessToken = localStorage.getItem('accessToken');
        const url = isEditMode ? `${process.env.REACT_APP_API_URL}/api/v1/cards/${carteToEdit?.id}` : `${process.env.REACT_APP_API_URL}/api/v1/cards`;
        const method = isEditMode ? 'PUT' : 'POST';
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                prenom: formData.prenom,
                nom: formData.nom,
                telephone: formData.telephone,
                email: formData.email,
                profession: formData.profession,
                site: formData.site,
                adresse: formData.adresse,
                type: type,
                typeId: typeId,
                country: selectedCountry?.id
            })
        }).then(response => response.json())
            .then(data => {
                console.log('SuccessCarte:', data);


                setLoading(false);
                if (isEditMode) {
                    if (data.code) {
                        notify(data.message, 'error');
                    } else {
                        onCarteUpdated(data);
                        notify(isEditMode ? "Carte modifié avec succès !" : "Carte ajouté avec succès !");
                    }

                } else {
                    onCarteAdded(data);
                    notify(isEditMode ? "Carte modifié avec succès !" : "Carte ajouté avec succès !");
                }
            })
            .catch((error) => {
                console.error('ErrorProjet:', error);
                notify("Erreur lors de l'ajout de la Carte");
                setLoading(false);
            });
    };
    return (
        <>

            <div class="modal fade" id="addCarteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-lg modal-dialog modal-dialog-centered  modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            {
                                isEditMode ? <h1 className="modal-title fs-4" id="exampleModalLabel">Modifier Carte</h1> : <h1 className="modal-title fs-4" id="exampleModalLabel">Créer Carte</h1>
                            }

                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <form onSubmit={handleSubmit} >

                                <div className='mb-3'>

                                    <label htmlFor="prenom" class="form-label">Prénom *</label>
                                    <input type="text" className={`form-control ${errors.prenom ? 'is-invalid' : ''}`} id="prenom" name="prenom" value={formData.prenom} onChange={handleChange} placeholder="Veuillez saisir le prénom" />
                                    {errors.prenom && <div className="invalid-feedback">{errors.prenom}</div>}

                                </div>

                                <div className='mb-3'>

                                    <label htmlFor="nom" class="form-label">Nom *</label>
                                    <input type="text" className={`form-control ${errors.nom ? 'is-invalid' : ''}`} id="nom" name="nom" value={formData.nom} onChange={handleChange} placeholder="Veuillez saisir le nom" />
                                    {errors.nom && <div className="invalid-feedback">{errors.nom}</div>}

                                </div>


                                <div className='mb-3'>

                                    <label htmlFor="telephone" class="form-label">Téléphone *</label>
                                    {/* <input type="tel" className={`form-control ${errors.telephone ? 'is-invalid' : ''}`} id="telephone" name="telephone" value={formData.telephone} onChange={handleChange} placeholder="Veuillez saisir le telephone" />
                                    {errors.telephone && <div className="invalid-feedback">{errors.telephone}</div>} */}

                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            id="countryCode"
                                            className="form-control select-container"
                                            placeholder="indicatif"
                                            value={selectedCountry ? selectedCountry.code : ''}
                                            onClick={() => setShowModal(true)}
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            className={`form-control  select-container1 ${errors.telephone ? 'is-invalid' : ''}`}
                                            id="telephone"
                                            name="telephone"
                                            placeholder="Ex:77889900"
                                            value={formData.telephone}
                                            onChange={handleChange}
                                        />

                                    </div>

                                </div>

                                <div className='mb-3'>

                                    <label htmlFor="email" class="form-label">Email *</label>
                                    <input type="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Veuillez saisir l'email" />
                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}

                                </div>

                                <div className='mb-3'>

                                    <label htmlFor="site" class="form-label">Site web *</label>
                                    <input type="text" className={`form-control ${errors.site ? 'is-invalid' : ''}`} id="site" name="site" value={formData.site} onChange={handleChange} placeholder="Veuillez saisir le lien du site web" />
                                    {errors.site && <div className="invalid-feedback">{errors.site}</div>}

                                </div>

                                <div className='mb-3'>

                                    <label htmlFor="profession" class="form-label">Profession *</label>
                                    <input type="text" className={`form-control ${errors.profession ? 'is-invalid' : ''}`} id="profession" name="profession" value={formData.profession} onChange={handleChange} placeholder="Veuillez saisir votre profession" />
                                    {errors.profession && <div className="invalid-feedback">{errors.profession}</div>}

                                </div>


                                <div className="mb-3">
                                    <label htmlFor="adresse" className="form-label">Adresse *</label>
                                    <textarea id="adresse" className={`form-control ${errors.adresse ? 'is-invalid' : ''}`} name="adresse" value={formData.adresse} onChange={handleChange} placeholder="Veuillez saisir l'adresse"></textarea>
                                    {errors.adresse && <div className="invalid-feedback">{errors.adresse}</div>}
                                </div>

                                <div className="w-100 d-flex justify-content-end">
                                    <button className='btn-ad' type="submit" disabled={loading}>
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : isEditMode ? 'Modifier' : 'Ajouter'}
                                    </button>

                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} className='modal-react'>
                <Modal.Header closeButton>
                    <Modal.Title>Select Country</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="list-group">
                        {countries.map((country) => (
                            <li
                                key={country.id}
                                className="list-group-item"
                                onClick={() => { handleCountrySelect(country) }}
                                style={{ cursor: 'pointer' }}
                            >
                                {country.nom} ({country.code})
                            </li>
                        ))}
                    </ul>
                </Modal.Body>

            </Modal>

        </>
    );
}

export default CarteForm;