// /* eslint-disable react/jsx-no-undef */
// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// import Accueil from './Accueil/Accueil';
// import Header from './Header';
// import Organisation from './Organisation/Organisation';
// import OrganisationDetail from './Organisation/OrganisationDetail';
// import Notification from './Notification/Notification';
// import MesCartes from './MesCarte/MesCartes';
// import Abonnement from './Abonnement/Abonnement';
// import PostDetail from './Post/PostDetail';
// import Main from './Main/Main';
// import Profil from './Profil/Profil';
// import EvenementDetail from './Evenement/EvenementDetail';
// import ProjetDetail from './Projet/ProjetDetail';
// import AllOrganisation from './Organisation/AllOrganisation';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Router>
//       <Routes>
//         <Route path="/Login" element={<Main />} />
//         <Route path="*" element={
//           <>
//             <Header />
//             <Routes>
//               <Route path="/" element={<Accueil />} />
//               <Route path="/organisation" element={<Organisation />} />
//               <Route path="/organisation-detail/:id" element={<OrganisationDetail />} />
//               <Route path="/organisation-list/:id" element={<AllOrganisation />} />
//               <Route path="/notification" element={<Notification />} />
//               <Route path="/mes-cartes" element={<MesCartes />} />
//               <Route path="/abonnement" element={<Abonnement />} />
//               <Route path="/post-detail/:id" element={<PostDetail />} />
//               <Route path="/event-detail/:id" element={<EvenementDetail />} />
//               <Route path="/projet-detail/:id" element={<ProjetDetail />} />
//               <Route path="/profil" element={<Profil />} />
//             </Routes>
//           </>
//         } />
//       </Routes>
//     </Router>
//   </React.StrictMode>
// );

// reportWebVitals();

/* eslint-disable react/jsx-no-undef */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Accueil from './Accueil/Accueil';
import Header from './Header';
import Organisation from './Organisation/Organisation';
import OrganisationDetail from './Organisation/OrganisationDetail';
import Notification from './Notification/Notification';
import MesCartes from './MesCarte/MesCartes';
import Abonnement from './Abonnement/Abonnement';
import PostDetail from './Post/PostDetail';
import Main from './Main/Main';
import Profil from './Profil/Profil';
import EvenementDetail from './Evenement/EvenementDetail';
import ProjetDetail from './Projet/ProjetDetail';
import AllOrganisation from './Organisation/AllOrganisation';
import PrivateRoute from './PrivateRoute'; // Importez le composant PrivateRoute
import ListEvenement from './Evenement/ListEvenement';
import ListPost from './Post/ListPost';
import LisProjet from './Projet/ListProjet';
import ListSondage from './Sondage/ListSondage';
import SondageDetail from './Sondage/SondageDetail';
import StatusForm from './Status/AddStatus';
import QRCodePage from './Opportunite/qrcode';
import Opportunite from './Opportunite/opportunite';
import CartesDetail from './MesCarte/CartesDetail';
import NotFound from './NotFound';
import Layout from './Layout';
import StatusViewer from './Status/StatusViewer';
// import * as serviceWorker from './serviceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/Login" element={<Main />} />

        <Route element={<Layout />}>
          <Route path="/" element={<PrivateRoute element={Accueil} />} />
          <Route path="/organisation" element={<PrivateRoute element={Organisation} />} />
          <Route path="/organisation-detail/:id" element={<PrivateRoute element={OrganisationDetail} />} />
          <Route path="/organisation-list/:id" element={<PrivateRoute element={AllOrganisation} />} />
          <Route path="/notification" element={<PrivateRoute element={Notification} />} />
          <Route path="/mes-cartes" element={<PrivateRoute element={MesCartes} />} />
          <Route path="/abonnement" element={<PrivateRoute element={Abonnement} />} />
          <Route path="/post-detail/:id" element={<PrivateRoute element={PostDetail} />} />
          <Route path="/post-list" element={<PrivateRoute element={ListPost} />} />
          <Route path="/event-detail/:id" element={<PrivateRoute element={EvenementDetail} />} />
          <Route path="/event-list" element={<PrivateRoute element={ListEvenement} />} />
          <Route path="/projet-detail/:id" element={<PrivateRoute element={ProjetDetail} />} />
          <Route path="/projet-list" element={<PrivateRoute element={LisProjet} />} />
          <Route path="/sondage-list" element={<PrivateRoute element={ListSondage} />} />
          <Route path="/sondage-detail/:id" element={<PrivateRoute element={SondageDetail} />} />
          <Route path="/profil" element={<PrivateRoute element={Profil} />} />
          <Route path="/opportunite" element={<PrivateRoute element={Opportunite} />} />
          <Route path="/add-status" element={<PrivateRoute element={StatusForm} />} />
          <Route path="/qrcode" element={<PrivateRoute element={QRCodePage} />} />
          <Route path="/cartes-detail/:id" element={<PrivateRoute element={CartesDetail} />} />

        </Route>
        <Route path="/status-detail/:id" element={<PrivateRoute element={StatusViewer} />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} /> {/* Catch-all for unmatched routes */}

        {/* Route NotFound sans le header */}

      </Routes>
    </Router>
  </React.StrictMode>
);

// serviceWorker.register();
serviceWorkerRegistration.register();
{/* <App /> */ }
// let deferredPrompt;

// window.addEventListener('beforeinstallprompt', (e) => {
//   // Empêcher l'invite automatique d'apparaître
//   e.preventDefault();
//   // Stocker l'événement pour l'utiliser plus tard
//   deferredPrompt = e;

//   // Afficher le bouton d'installation
//   const installButton = document.getElementById('installButton');
//   installButton.style.display = 'block';

//   // Ajouter un événement 'click' pour déclencher l'installation
//   installButton.addEventListener('click', () => {
//     // Afficher l'invite d'installation
//     deferredPrompt.prompt();

//     // Attendre la réponse de l'utilisateur
//     deferredPrompt.userChoice.then((choiceResult) => {
//       if (choiceResult.outcome === 'accepted') {
//         console.log('Utilisateur a accepté l\'invite d\'installation');
//       } else {
//         console.log('Utilisateur a refusé l\'invite d\'installation');
//       }
//       // Réinitialiser deferredPrompt
//       deferredPrompt = null;
//     });
//   });
// });

// // Cacher le bouton après l'installation de l'application
// window.addEventListener('appinstalled', () => {
//   console.log('L’application a été installée');
//   const installButton = document.getElementById('installButton');
//   installButton.style.display = 'none';
// });


// // Gérer l'événement beforeinstallprompt
// let deferredPrompt;
// const installButton = document.createElement('button');
// installButton.innerText = 'Installer Holilink';
// installButton.style.display = 'none';
// document.body.appendChild(installButton);

// window.addEventListener('beforeinstallprompt', (e) => {
//   // Empêcher l'affichage automatique de la bannière d'installation
//   e.preventDefault();
//   // Stocker l'événement pour l'utiliser plus tard
//   deferredPrompt = e;
//   // Afficher le bouton d'installation
//   installButton.style.display = 'block';
//   installButton.style.backgroundColor = '#2F6195';

//   installButton.addEventListener('click', () => {
//     // Masquer le bouton d'installation
//     installButton.style.display = 'none';
//     // Afficher la bannière d'installation
//     deferredPrompt.prompt();
//     // Attendre la réponse de l'utilisateur
//     deferredPrompt.userChoice.then((choiceResult) => {
//       if (choiceResult.outcome === 'accepted') {
//         console.log('User accepted the install prompt');
//       } else {
//         console.log('User dismissed the install prompt');
//       }
//       deferredPrompt = null;
//     });
//   });
// });

reportWebVitals();
