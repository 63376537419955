/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */

import '../Organisation/Organisation.css'
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import bootstrap from 'bootstrap';

import seting from '../image/seting.png'

import logo_site from '../image/logo-site/image.jpg'

import './MesCartes.css'
// import location from '../image/icon-card/location.png'

// import web from '../image/Organisation-logo/web.png'
// import phone from '../image/Organisation-logo/phone.png'
// import email from '../image/Organisation-logo/email.png'
// import detail from '../image/vue.png'
// import modifier from '../image/modifier.png'
// import supprimer from '../image/supprimer.png'
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Left from '../Accueil/Left'
import add from '../image/icon-card/add.png'
import CarteForm from './AddCarte'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SkeletonLoaderPost from '../SkeletonLoad/skeletonloaderPost'
// import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
function MesCartes() {
    const [cardsCompte, setCardsCompte] = useState([]);
    const [entreprise, setEntreprise] = useState([]);
    const [type, setType] = useState('');
    const [typeId, setTypeId] = useState('');
    // const [country, setCountry] = useState('');
    const count = JSON.parse(localStorage.getItem('account'));
    // const organisation = JSON.parse(localStorage.getItem('organisation'))
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const [loadingOrganisation, setLoadingOrganisation] = useState(true);
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new window.bootstrap.Popover(popoverTriggerEl))
    const navigate = useNavigate();
    const [isEditMode, setIsEditMode] = useState(false);
    // const [idCartSelected, setIdCartSelected] = useState('');
    const [cartes, setCartes] = useState([]);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const fetchData = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/cards/organisation`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            // console.log(fil)
            setEntreprise(response.data);
            setLoadingOrganisation(false);
        } catch (error) {
            console.error('Error fetching posts:', error);
            setLoadingOrganisation(false);
        }
    };

    const fetchDataCompe = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/cards/compte`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log(response.data)
            setCardsCompte(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching posts:', error);
            setLoading(false);
        }
    };


    const handleCartAdded = (newPost) => {
        setCardsCompte([newPost, ...cardsCompte]);
    };

    const handleType = (e) => {
        console.log(e)
        setType(e)
    }
    const handleTypeId = (e) => {
        console.log(e)
        // e === null ? notify("Ce compte n'est pas autorisé à créer une carte") : setTypeId(e)
        setTypeId(e)
    }

    const handleDeleteCarte = async (id) => {
        // e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/cards/${id}/${type}/${typeId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            fetchData(); // Refresh comments after deletion
            fetchDataCompe();
            navigate('/mes-cartes');
            console.log('text');

        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    useEffect(() => {
        fetchDataCompe()
        fetchData();

    }, []);

    const handleSetCart = (idCartSelected) => {
        const combinedCarte = [...cardsCompte, ...entreprise.membres, ...entreprise.clients];
        console.log(combinedCarte);
        combinedCarte.map((carte, index) => {
            if (carte.id === idCartSelected) {
                setCartes(carte)
            }
        });
    }

    const handleCarteUpdated = (updatedProjet) => {
        setCartes(updatedProjet);
        fetchDataCompe()
        fetchData();
    };

    const [organisation, setOrganisation] = useState(JSON.parse(localStorage.getItem('organisation')) || '');
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    useEffect(() => {
        // Définir un intervalle qui vérifie la valeur dans le localStorage toutes les secondes
        const intervalId = setInterval(() => {
            const currentOrganisation = localStorage.getItem('organisation') || '';
            if (currentOrganisation !== organisation) {
                setOrganisation(currentOrganisation);
            }
        }, 1000); // Intervalle de 1 seconde

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, [organisation]); // Dépendance à l'état "organisation"


    const VerificatioCompte = (e) => {

        if (!organisation) {
            notify('Veillez changer de compte pour pouvoir créer une carte d\'entreprise', 'error')
        } else {
            // Ouvre le modal Bootstrap pur avec jQuery ou JavaScript pur
            const scanModal = document.getElementById('addCarteModal');
            const bootstrapModal = new window.bootstrap.Modal(scanModal);
            bootstrapModal.show();
            handleType('organisation');
            handleTypeId(organisation.id)
        }
    }

    return (
        <div className="row m-1">



            <div className="col-12 col-sm-12 col-md-12 col-lg-8">

                <div class="w-100 tabs1  p-1 d-flex flex-column flex-md-row flex-lg-row justify-content-around" id="pills-tab" role="tablist">

                    <button class="nav-link active butn3" id="v-pills-mesCarte-tab" data-bs-toggle="pill" data-bs-target="#v-pills-mesCarte" type="button" role="tab" aria-controls="v-pills-mesCarte" aria-selected="true">Mes Cartes </button>
                    <button class="nav-link butn3" id="v-pills-entrepriseCarte-tab" data-bs-toggle="pill" data-bs-target="#v-pills-entrepriseCarte" type="button" role="tab" aria-controls="v-pills-entrepriseCarte" aria-selected="false">Mes Cartes Entreprise</button>

                </div>


                <div className="right p-md-4 mx-md-1 my-2 d-flex justify-content-center">



                    <div class="tab-content w-100" id="v-pills-tabContent">

                        <div class="tab-pane  fade show active " id="v-pills-mesCarte" role="tabpanel" aria-labelledby="v-pills-mesCarte-tab" tabindex="0">

                            <div className='px-2 pb-4 btn-add button-stl' data-bs-toggle="modal" onClick={() => {
                                handleType('compte');
                                handleTypeId(count.id)

                            }} data-bs-target="#addCarteModal" >
                                <img src={add} class="rounded-start flex-shrink-1" alt="..." />
                            </div>

                            {
                                loading ? (
                                    <SkeletonLoaderPost />
                                ) : (<div class="container">
                                    <div class="row">
                                        {
                                            Array.isArray(cardsCompte) && cardsCompte.map(cards => (
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-4" key={cards.id}>
                                                    <div class="our-team">
                                                        <div className='d-flex justify-content-end'>
                                                            <div class="dropdown dropdown1">
                                                                <button class="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    {/* <img src={seting} width="25px" alt='..' /> */}
                                                                    <FontAwesomeIcon icon={faEllipsisVertical} className='icon' />
                                                                </button>
                                                                <ul class="dropdown-menu dropdown-menu-white">
                                                                    <Link class="nav-link" to={`/cartes-detail/${cards.id}`}>
                                                                        <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >
                                                                            {/* <img src={detail} className='icon-seting' alt='...' /> */}
                                                                            <FontAwesomeIcon icon={faEye} className='icon-seting' />
                                                                            Detail
                                                                        </li>
                                                                    </Link>
                                                                    <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' onClick={() => {
                                                                        handleType('compte');
                                                                        handleTypeId(count.id);
                                                                        setIsEditMode(true);
                                                                        handleSetCart(cards.id)

                                                                    }} data-bs-toggle="modal" data-bs-target="#addCarteModal"  >

                                                                        <FontAwesomeIcon icon={faPen} className='icon-seting' />
                                                                        <Link class="nav-link" to="#">Modifier</Link>
                                                                    </li>

                                                                    <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' onClick={() => {
                                                                        handleType('compte');
                                                                        handleTypeId(count.id)

                                                                    }} data-bs-toggle="modal" data-bs-target="#supCarteModal" >

                                                                        <FontAwesomeIcon icon={faTrashCan} className='icon-seting' />
                                                                        <Link class="nav-link" to="#">Supprimer</Link>
                                                                    </li>


                                                                </ul>
                                                            </div>
                                                            <div className="modal fade" id="supCarteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div className=" modal-dialog modal-dialog-centered ">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">

                                                                            <h5 className='modal-title fs-4'>Supprimer la Carte</h5>

                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>
                                                                        <div className="modal-body">

                                                                            <div className='text-center'>

                                                                                <p>Vous êtes sur le point de supprimer une carte. Voulez-vous continuer?</p>


                                                                            </div>

                                                                            <div className='d-flex justify-content-between align-item-center'>
                                                                                <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">Non</button>
                                                                                <button className="btn btn-success px-3" type="button" onClick={() => handleDeleteCarte(cards.id)} data-bs-dismiss="modal"> Oui</button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="picture">
                                                            {
                                                                cards.photo ? <img src={FILE_URL + cards.photo} className='img-fluid' alt='..' /> : <img src={logo_site} className='img-fluid' alt='..' />
                                                            }
                                                            {/* <img class="img-fluid" src="https://picsum.photos/130/130?image=1027" /> */}
                                                        </div>
                                                        <div class="team-content">
                                                            <h5 class="name">{cards.prenom} {cards.nom}</h5>
                                                            <h4 class="title">{cards.profession}</h4>

                                                        </div>

                                                        <div class="social">

                                                            <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.adresse}`}>
                                                                <FontAwesomeIcon icon={faLocationDot} className='icon' />
                                                            </button>
                                                            <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.site}`}>
                                                                <FontAwesomeIcon icon={faGlobe} className='icon' />
                                                            </button>
                                                            <button type="button" class="" data-bs-container="body" key={cards.emails[0].id} data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards?.emails[0]?.email}`}>
                                                                <FontAwesomeIcon icon={faEnvelope} className='icon' />
                                                            </button>
                                                            <button type="button" class="" data-bs-container="body" key={cards?.phones[0].id} data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards?.phones[0]?.phone}`}>
                                                                <FontAwesomeIcon icon={faPhoneAlt} className='icon' />
                                                            </button>


                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }


                                    </div>
                                </div>)
                            }

                        </div>

                        <div class="tab-pane  fade show" id="v-pills-entrepriseCarte" role="tabpanel" aria-labelledby="v-pills-entrepriseCarte-tab" tabindex="0">
                            <div className='px-2 pb-4 btn-add button-stl' onClick={VerificatioCompte} >
                                <img src={add} class="rounded-start flex-shrink-1" alt="..." />
                            </div>
                            <ul class="nav nav-tabs d-flex justify-content-between" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="membre-tab" data-bs-toggle="tab" data-bs-target="#membre-tab-pane" type="button" role="tab" aria-controls="membre-tab-pane" aria-selected="true">Membres</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="client-tab" data-bs-toggle="tab" data-bs-target="#client-tab-pane" type="button" role="tab" aria-controls="client-tab-pane" aria-selected="false">Clients</button>
                                </li>
                            </ul>
                            {
                                loadingOrganisation ? (
                                    <div className="right p-2 mx-2 my-2 h-25 position-relative">
                                        <div className='position-absolute top-50 start-50 translate-middle'>
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div class="tab-content m" id="myTabContent">
                                        <div class="tab-pane fade show mt-4 active" id="membre-tab-pane" role="tabpanel" aria-labelledby="membre-tab" tabindex="0">
                                            <div class="container">
                                                <div class="row">
                                                    {
                                                        Array.isArray(entreprise.membres) && entreprise.membres.map(cards => (
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4" key={cards.id}>
                                                                <div class="our-team">
                                                                    <div className='d-flex justify-content-end'>
                                                                        <div class="dropdown dropdown1">
                                                                            <button class="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                {/* <img src={seting} width="25px" alt='..' /> */}
                                                                                <FontAwesomeIcon icon={faEllipsisVertical} className='icon' />
                                                                            </button>
                                                                            <ul class="dropdown-menu dropdown-menu-white">
                                                                                <Link class="nav-link" to={`/cartes-detail/${cards.id}`}>
                                                                                    <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >
                                                                                        {/* <img src={detail} className='icon-seting' alt='...' /> */}
                                                                                        <FontAwesomeIcon icon={faEye} className='icon-seting' />
                                                                                        Detail
                                                                                    </li>
                                                                                </Link>
                                                                                {/* <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' onClick={() => {
                                                                                    handleType('compte');
                                                                                    handleTypeId(count.id);
                                                                                    setIsEditMode(true);
                                                                                    handleSetCart(cards.id)

                                                                                }} data-bs-toggle="modal" data-bs-target="#addCarteModal"  >

                                                                                    <FontAwesomeIcon icon={faPen} className='icon-seting' />
                                                                                    <Link class="nav-link" to="#">Modifier</Link>
                                                                                </li> */}

                                                                                {/* <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' onClick={() => {
                                                                                    handleType('organisation');
                                                                                    handleTypeId(organisation.id)

                                                                                }} data-bs-toggle="modal" data-bs-target="#supCarteModal" >

                                                                                    <FontAwesomeIcon icon={faTrashCan} className='icon-seting' />
                                                                                    <Link class="nav-link" to="#">Supprimer</Link>
                                                                                </li> */}


                                                                            </ul>
                                                                        </div>
                                                                        <div className="modal fade" id="supCarteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                            <div className=" modal-dialog modal-dialog-centered ">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">

                                                                                        <h5 className='modal-title fs-4'>Supprimer la Carte</h5>

                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div className="modal-body">

                                                                                        <div className='text-center'>

                                                                                            <p>Vous êtes sur le point de supprimer une carte. Voulez-vous continuer?</p>


                                                                                        </div>

                                                                                        <div className='d-flex justify-content-between align-item-center'>
                                                                                            <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">Non</button>
                                                                                            <button className="btn btn-success px-3" type="button" onClick={() => handleDeleteCarte(cards.id)} data-bs-dismiss="modal"> Oui</button>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="picture">
                                                                        {
                                                                            cards.photo ? <img src={FILE_URL + cards.photo} className='img-fluid' alt='..' /> : <img src={logo_site} className='img-fluid' alt='..' />
                                                                        }
                                                                        {/* <img class="img-fluid" src="https://picsum.photos/130/130?image=1027" /> */}
                                                                    </div>
                                                                    <div class="team-content">
                                                                        <h5 class="name">{cards.prenom} {cards.nom}</h5>
                                                                        <h4 class="title">{cards.profession}</h4>

                                                                    </div>

                                                                    <div class="social">
                                                                        {
                                                                            cards.adresse && <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.adresse}`}>
                                                                                <FontAwesomeIcon icon={faLocationDot} className='icon' />
                                                                            </button>
                                                                        }
                                                                        {
                                                                            cards.site && <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.site}`}>
                                                                                <FontAwesomeIcon icon={faGlobe} className='icon' />
                                                                            </button>
                                                                        }


                                                                        {
                                                                            cards.emails && <button type="button" class="" data-bs-container="body" key={cards.emails[0].id} data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards?.emails[0]?.email}`}>
                                                                                <FontAwesomeIcon icon={faEnvelope} className='icon' />
                                                                            </button>
                                                                        }
                                                                        {
                                                                            cards.phones && <button type="button" class="" data-bs-container="body" key={cards?.phones[0].id} data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards?.phones[0]?.phone}`}>
                                                                                <FontAwesomeIcon icon={faPhoneAlt} className='icon' />
                                                                            </button>
                                                                        }
                                                                        


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }


                                                </div>
                                            </div>

                                        </div>
                                        <div class="tab-pane fade show mb-2 mt-4" id="client-tab-pane" role="tabpanel" aria-labelledby="client-tab" tabindex="0">
                                            <div class="container">
                                                <div class="row">
                                                    {
                                                        Array.isArray(entreprise.clients) && entreprise.clients.map(cards => (
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4" key={cards.id}>
                                                                <div class="our-team">
                                                                    <div className='d-flex justify-content-end'>
                                                                        <div class="dropdown dropdown1">
                                                                            <button class="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                {/* <img src={seting} width="25px" alt='..' /> */}
                                                                                <FontAwesomeIcon icon={faEllipsisVertical} className='icon' />
                                                                            </button>
                                                                            <ul class="dropdown-menu dropdown-menu-white">
                                                                                <Link class="nav-link" to={`/cartes-detail/${cards.id}`}>
                                                                                    <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >
                                                                                        {/* <img src={detail} className='icon-seting' alt='...' /> */}
                                                                                        <FontAwesomeIcon icon={faEye} className='icon-seting' />
                                                                                        Detail
                                                                                    </li>
                                                                                </Link>

                                                                                {/* <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' onClick={() => {
                                                                                    handleType('compte');
                                                                                    handleTypeId(count.id)

                                                                                }} data-bs-toggle="modal" data-bs-target="#supCarteModal" >

                                                                                    <FontAwesomeIcon icon={faTrashCan} className='icon-seting' />
                                                                                    <Link class="nav-link" to="#">Supprimer</Link>
                                                                                </li> */}


                                                                            </ul>
                                                                        </div>
                                                                        <div className="modal fade" id="supCarteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                            <div className=" modal-dialog modal-dialog-centered ">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">

                                                                                        <h5 className='modal-title fs-4'>Supprimer la Carte</h5>

                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div className="modal-body">

                                                                                        <div className='text-center'>

                                                                                            <p>Vous êtes sur le point de supprimer une carte. Voulez-vous continuer?</p>


                                                                                        </div>

                                                                                        <div className='d-flex justify-content-between align-item-center'>
                                                                                            <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">Non</button>
                                                                                            <button className="btn btn-success px-3" type="button" onClick={() => handleDeleteCarte(cards.id)} data-bs-dismiss="modal"> Oui</button>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="picture">
                                                                        {
                                                                            cards.photo ? <img src={FILE_URL + cards.photo} className='img-fluid' alt='..' /> : <img src={logo_site} className='img-fluid' alt='..' />
                                                                        }
                                                                        {/* <img class="img-fluid" src="https://picsum.photos/130/130?image=1027" /> */}
                                                                    </div>
                                                                    <div class="team-content">
                                                                        <h5 class="name">{cards.prenom} {cards.nom}</h5>
                                                                        <h4 class="title">{cards.profession}</h4>

                                                                    </div>

                                                                    <div class="social">

                                                                        <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.adresse}`}>
                                                                            <FontAwesomeIcon icon={faLocationDot} className='icon' />
                                                                        </button>
                                                                        <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.site}`}>
                                                                            <FontAwesomeIcon icon={faGlobe} className='icon' />
                                                                        </button>
                                                                        <button type="button" class="" data-bs-container="body" key={cards.emails[0].id} data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards?.emails[0]?.email}`}>
                                                                            <FontAwesomeIcon icon={faEnvelope} className='icon' />
                                                                        </button>
                                                                        <button type="button" class="" data-bs-container="body" key={cards?.phones[0].id} data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards?.phones[0]?.phone}`}>
                                                                            <FontAwesomeIcon icon={faPhoneAlt} className='icon' />
                                                                        </button>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            }




                        </div>
                    </div>



                </div >

                <ToastContainer transition={Zoom} />
                {
                    isEditMode ? <CarteForm onCarteAdded={() => {/* Rafraîchir la liste des posts */ }} type={type} typeId={typeId} carteToEdit={cartes} isEditMode={isEditMode} onCarteUpdated={handleCarteUpdated} /> : <CarteForm onCarteAdded={handleCartAdded} type={type} typeId={typeId} />
                }


            </div >
            <div className="col-4">
                <Left />

            </div>
        </div >
    )

}

export default MesCartes;