/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Bounce, Slide, Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function PostForm({ onPostAdded, onPostUpdated, postToEdit, isEditMode }) {
    const idOrganisation = JSON.parse(localStorage.getItem("organisation"))?.id;

    // const [post, setPost] = useState([]);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"

    const [formData, setFormData] = useState({
        couverture: null,
        titre: '',
        description: '',
        ...(idOrganisation && { organisationId: idOrganisation })
    });
    const [currentImage, setCurrentImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (postToEdit) {
            setFormData({
                couverture: null,
                titre: postToEdit.titre,
                description: postToEdit.description,
                ...(idOrganisation && { organisationId: idOrganisation })
            });
            setCurrentImage(postToEdit.couverture); // Mettez à jour l'image actuelle
        }
    }, [postToEdit]);

    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.titre) newErrors.titre = 'Le titre est requis';
        if (!formData.description) newErrors.description = 'La description est requise';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!idOrganisation) {
            notify("Ce compte n'est pas autorisé à créer un post", 'error');
            return;
        }
        if (!validateForm()) return;

        setLoading(true);
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
        const accessToken = localStorage.getItem('accessToken');
        const url = isEditMode ? `${process.env.REACT_APP_API_URL}/api/v1/posts/${postToEdit.id}` : `${process.env.REACT_APP_API_URL}/api/v1/posts`;
        const method = isEditMode ? 'PUT' : 'POST';

        fetch(url, {
            method: method,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            body: data
        }).then(response => response.json())
            .then(data => {
                console.log('SuccessPost:', data);
                notify(isEditMode ? "Post modifié avec succès !" : "Post ajouté avec succès !");
                setLoading(false);
                if (isEditMode) {
                    onPostUpdated(data);
                } else {
                    onPostAdded(data);
                }
                
            })
            .catch((error) => {
                console.error('ErrorPost:', error);
                notify("Erreur lors de l'ajout du post", 'error');
                setLoading(false);
            });
    };



    return (
        <>
            <div className="modal fade" id="addpostModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            {
                                isEditMode ? <h1 className="modal-title fs-4" id="exampleModalLabel">Modifier Post</h1> : <h1 className="modal-title fs-4" id="exampleModalLabel">Créer Post</h1>
                            }
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                                <div className='mb-3'>
                                    <label htmlFor="titre" className="form-label">Titre</label>
                                    <input type="text" className={`form-control ${errors.titre ? 'is-invalid' : ''}`} id="titre" name="titre" value={formData.titre} onChange={handleChange} placeholder="Veuillez saisir le titre" />
                                    {errors.titre && <div className="invalid-feedback">{errors.titre}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <textarea id="description" className={`form-control ${errors.description ? 'is-invalid' : ''}`} name="description" value={formData.description} onChange={handleChange} required placeholder="Veuillez saisir la description de votre post"></textarea>
                                    {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor="couverture" className="form-label">Couverture</label>
                                    <input type="file" className="form-control" id="couverture" name="couverture" onChange={handleChange} />
                                    {/* {currentImage && (
                                        <div className="mt-2">
                                            <img src={FILE_URL+currentImage} alt="Current Cover" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }} />
                                        </div>
                                    )} */}
                                </div>
                                <div className="w-100 d-flex justify-content-end">
                                    <button className='btn-ad' type="submit" disabled={loading}>
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : isEditMode ? 'Modifier' : 'Ajouter'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <ToastContainer transition={Zoom} />
            </div>
        </>
    );
}

export default PostForm;