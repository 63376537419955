import React from 'react';
import './Article.css';

function Articles() {
    return (
        <>
            <section className="articles d-flex flex-column flex-md-row">
                <div className='w content p-3'>
                    <h2 className="text-center text-lg-start">Réseau Social Professionnel</h2>
                    <div className="text-center text-lg-start">
                        Holilink connecte les entreprises et leurs clients en permettant de publier :
                    </div>
                </div>

                <div className="tags flex-fill d-flex flex-wrap justify-content-center p-3">
                    <button className=" m-1 p-2">Posts</button>
                    <button className=" m-1 p-2">Sondages</button>
                    <button className=" m-1 p-2">Événements</button>
                    <button className=" m-1 p-2">Services</button>
                    <button className=" m-1 p-2">Statuts</button>
                </div>
            </section>
            <section className="job-offers d-flex flex-column flex-md-row">
                <div className='w p-3'>
                    <h2 className="text-center text-lg-start">Digitalisation des Cartes de Visite</h2>
                    <div className="text-center text-lg-start">
                        Avec Holilink, transformez vos cartes de visite physiques en cartes digitales. Cette fonctionnalité vous permet entre autre de :
                    </div>
                </div>
                <div className="services flex-fill d-flex flex-wrap justify-content-center p-3">
                    <button className=" m-1 service p-2">Création de Cartes Digitales</button>
                    <button className=" m-1 service p-2">Partage Simplifié</button>
                    <button className=" m-1 service p-2">Gestion Centralisée</button>
                    <button className=" m-1 service p-2">Mises à Jour Faciles</button>
                </div>
            </section>
            <section className="tools d-flex flex-column flex-md-row">
                <div className='w p-3'>
                    <h2 className="text-center text-lg-start">Engagement et Interaction</h2>
                    <div className="text-center text-lg-start">
                        Holilink favorise l'engagement et l'interaction entre les entreprises et leurs clients grâce à des fonctionnalités interactives telles que :
                    </div>
                </div>

                <div className="products flex-fill d-flex flex-wrap justify-content-center p-3">
                    <button className=" m-1 product p-2">Interaction par Posts</button>
                    <button className=" m-1 product p-2">Feedback Instantané</button>
                    <button className=" m-1 product p-2">Promotion d'Événements</button>
                    <button className=" m-1 product p-2">Communication en Temps Réel</button>
                    <button className=" m-1 product p-2">Suivi des Interactions</button>
                </div>
            </section>
        </>
    );
}

export default Articles;